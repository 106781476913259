import request from '@/utils/request'


export function getEventDataFromFirebase() {
    return request({
        url: '/dynamicevents/getconfigdata',
        method: 'get'
    })
}

export async function getEventDataFromFirebaseV2() {
    return await request({
        url: '/dynamicevents/getconfigdata',
        method: 'get'
    })
}

export function activateEventDataFromFirebase(rowID, user, active) {
    return request({
        url: '/dynamicevents/activatedata/' + rowID + "/" + user + "/" + active,
        method: 'get'
    })
}

export function activateTestingDataFromFirebase(rowID, user, active) {
    return request({
        url: '/dynamicevents/activatetesting/' + rowID + "/" + user + "/" + active,
        method: 'get'
    })
}

export function deleteEventFromFirebase(rowID, user) {
    return request({
        url: '/dynamicevents/deletedata/' + rowID + '/' + user,
        method: 'get'
    })
}

export function moveEventFromFirebase(type, rowID, user) {
    return request({
        url: '/dynamicevents/moveupdown/' + type + '/' + rowID + '/' + user,
        method: 'get'
    })
}

export function uploadEventToFirebase(eventID,
    eventName,
    eventType,
    from_version,
    to_version,
    from_time,
    to_time,
    coming_soon_time,
    isActivated,
    isTesting,
    user,
    fileNameListMain,
    fileNameListOthers,
    fileSizeListMain,
    fileSizeListOthers,
) {
    return request({
        url: '/dynamicevents/upload/',
        method: 'post',
        data: {
            eventID: eventID,
            eventName: eventName,
            eventType: eventType,
            from_version: from_version,
            to_version: to_version,
            from_time: from_time,
            to_time: to_time,
            coming_soon_time: coming_soon_time,
            activated: isActivated,
            testing: isTesting,
            user: user,
            fileNameListMain: fileNameListMain,
            fileNameListOthers: fileNameListOthers,
            fileSizeListMain: fileSizeListMain,
            fileSizeListOthers: fileSizeListOthers
        }
    })
}

export function editEventToFirebase(
    row_id,
    eventID,
    eventName,
    eventType,
    from_version,
    to_version,
    from_time,
    to_time,
    coming_soon_time,
    isActivated,
    isTesting,
    user
) {

    return request({
        url: '/dynamicevents/updatedata/',
        method: 'post',
        data: {
            row_id: row_id,
            eventID: eventID,
            eventName: eventName,
            eventType: eventType,
            from_version: from_version,
            to_version: to_version,
            from_time: from_time,
            to_time: to_time,
            coming_soon_time: coming_soon_time,
            activated: isActivated,
            testing: isTesting,
            user: user
        }
    })
}


export function events_holdingSystemToFirebase(
    is_holding,
    user) {

    return request({
        url: '/dynamicevents/holdingsystem/',
        method: 'post',
        data: {
            is_holding: is_holding,
            user: user
        }
    })
}