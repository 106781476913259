<style>
.event-input-label {
  display: inline-block;
  width: 140px;
}

.notice-style {
  background: #0065ac;
  color: #ffffff;
  font-size: 160%;
}
</style>


<template>
  <div class="app-container">
    <!-- Synchronize To CDN Status -->
    <template v-if="LeaderBoard_Apply_Settings_Status === APPLY_STATUS_ENABLE">
      <el-card class="box-card">
        <div>
          <el-button
            type="primary"
            size="small"
            @click="onApplyLeaderBoardSettings"
            >Apply LeaderBoard Settings</el-button
          >
        </div>
      </el-card>
      <br />
    </template>

    <template
      v-if="LeaderBoard_Apply_Settings_Status === APPLY_STATUS_PROCESSING"
    >
      <el-card class="box-card notice-style">
        <div>
          <span
            >Apply Settings Processing...{{
              LeaderBoard_Processing_Time_Inc
            }}s</span
          >
        </div>
      </el-card>
      <br />
    </template>

    <template v-if="LeaderBoard_Apply_Settings_Status === APPLY_STATUS_DONE">
      <el-card class="box-card notice-style">
        <div>
          <span>Application Of Settings Has Done.</span>
        </div>
      </el-card>
      <br />
    </template>
    <!-- End Synchronize To CDN Status -->

    <el-tabs v-model="activeNames" @tab-click="handleClick" v-loading="loading">
      <el-tab-pane label="LeaderBoard Settings" name="1">
        <template v-if="LeaderBoard_Is_Enable_Redirect">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <b
                ><el-input
                  v-model="LeaderBoard_Redirect_Message"
                  :readonly="true"
                ></el-input
              ></b>
            </div>
          </el-card>
          <br />
        </template>

        <el-tabs
          v-model="activeLeaderBoardTypes"
          @tab-click="handleClick"
          v-loading="loading"
        >
          <el-tab-pane label="LeaderBoard LBQ" name="1_1">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>LeaderBoard Settings</span>
              </div>
              <div>
                <el-table
                  :data="LeaderBoardSettingsList_LBQ"
                  style="width: 100%"
                >
                  <el-table-column label="ID" width="50px">
                    <template slot-scope="{ row }">
                      <span>{{ row.id }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column label="Name" width="120px">
                    <template slot-scope="{ row }">
                      <span>{{ row.data.name }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column label="EventID" width="120px">
                    <template slot-scope="{ row }">
                      <template v-if="row.editMode">
                        <el-input
                          v-model="row.data.EventInfo.eventID"
                          class="edit-input"
                          size="small"
                        />
                      </template>
                      <span v-else>{{ row.data.EventInfo.eventID }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column label="EventName" width="120px">
                    <template slot-scope="{ row }">
                      <template v-if="row.editMode">
                        <el-input
                          v-model="row.data.EventInfo.eventName"
                          class="edit-input"
                          size="small"
                        />
                      </template>
                      <span v-else>{{ row.data.EventInfo.eventName }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column label="Date" width="220px">
                    <template slot-scope="{ row }">
                      <template v-if="row.editMode">
                        From:
                        <el-date-picker
                          v-model="row.dataDisplay.EventInfo.startDate"
                          type="datetime"
                          placeholder="Select date and time"
                          format="dd/MM/yyyy HH:mm:ss"
                        >
                        </el-date-picker>
                        <br /><br />
                        To:
                        <el-date-picker
                          v-model="row.dataDisplay.EventInfo.endDate"
                          type="datetime"
                          placeholder="Select date and time"
                          format="dd/MM/yyyy HH:mm:ss"
                        >
                        </el-date-picker>
                      </template>
                      <span v-else
                        >From:
                        {{
                          row.dataDisplay.EventInfo.startDate.toLocaleString(
                            "en-GB", { hour12: false }
                          )
                        }}
                        <br /><br />
                        To:
                        {{
                          row.dataDisplay.EventInfo.endDate.toLocaleString(
                            "en-GB", { hour12: false }
                          )
                        }}</span
                      >
                      <br />
                    </template>
                  </el-table-column>

                  <el-table-column label="Groups" width="650px">
                    <template slot-scope="{ row }">
                      <!--<span>{{ row.editMode }}</span>-->
                      <template v-if="row.editMode">
                        <leader-board-group-list
                          v-bind:propID="row.id"
                          v-bind:propData="row.data.Groups"
                          v-bind:propEditMode="row.editMode"
                          v-on:CallbackChangeData="CallbackChangeGroupData"
                        ></leader-board-group-list>
                      </template>
                      <span v-else>
                        <leader-board-group-list
                          v-bind:propID="row.id"
                          v-bind:propData="row.data.Groups"
                          v-bind:propEditMode="row.editMode"
                          v-on:CallbackChangeData="CallbackChangeGroupData"
                        ></leader-board-group-list>
                      </span>
                    </template>
                  </el-table-column>

                  <!-- Actions for rewards -->
                  <el-table-column label="Actions" width="100px">
                    <template slot-scope="{ row }">
                      <template v-if="row.editMode">
                        <template v-if="row.updateMode">
                          <el-button
                            type="success"
                            size="small"
                            icon="el-icon-edit-outline"
                            disabled
                            @click="confirmEdit(row)"
                            >Update</el-button
                          >
                          <br />
                          <el-button
                            class="cancel-btn"
                            size="small"
                            icon="el-icon-refresh"
                            type="warning"
                            disabled
                            @click="cancelEdit(row)"
                            >Cancel</el-button
                          >
                        </template>
                        <template v-else>
                          <el-button
                            type="success"
                            size="small"
                            icon="el-icon-edit-outline"
                            @click="confirmEdit(row)"
                            >Update</el-button
                          >
                          <br />
                          <br />
                          <el-button
                            class="cancel-btn"
                            size="small"
                            icon="el-icon-refresh"
                            type="warning"
                            @click="cancelEdit(row)"
                            >Cancel</el-button
                          >
                        </template>
                      </template>
                      <template v-else>
                        <el-button
                          type="primary"
                          size="small"
                          icon="el-icon-edit"
                          @click="row.editMode = true"
                          >Edit</el-button
                        >
                        <br />
                        <br />
                        <el-button
                          type="danger"
                          size="small"
                          icon="el-icon-delete"
                          @click="onDelete(row)"
                          >Delete</el-button
                        >
                        <br />
                        <br />
                        <el-button
                          type="info"
                          size="small"
                          icon="el-icon-download"
                          @click="onExport(row)"
                          >Export</el-button
                        >
                      </template>
                    </template>
                  </el-table-column>

                  <el-table-column label="Rewards" width="1850px">
                    <template slot-scope="{ row }">
                      <!--<span>{{ row.editMode }}</span>-->
                      <template v-if="row.editMode">
                        <leader-board-reward-list
                          v-bind:propID="row.id"
                          v-bind:propData="row.data.Rewards"
                          v-bind:propEditMode="row.editMode"
                          v-on:CallbackChangeData="CallbackChangeRewardData"
                        ></leader-board-reward-list>
                      </template>
                      <span v-else>
                        <leader-board-reward-list
                          v-bind:propID="row.id"
                          v-bind:propData="row.data.Rewards"
                          v-bind:propEditMode="row.editMode"
                          v-on:CallbackChangeData="CallbackChangeRewardData"
                        ></leader-board-reward-list>
                      </span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-card>

            <br />

            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>LeaderBoard Status</span>
              </div>
              <div>
                <el-table
                  :data="LeaderBoardServiceList_LBQ"
                  style="width: 100%"
                >
                  <el-table-column label="Name" width="250px">
                    <template slot-scope="{ row }">
                      <span>{{ row.AppID }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column label="Status" width="150px">
                    <template slot-scope="{ row }">
                      <span>{{ row.EventStatus.Status }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column label="Current Time" width="250px">
                    <template slot-scope="{ row }">
                      <el-date-picker
                        v-model="row.DisplayEventStatus.CurrentTime"
                        type="datetime"
                        placeholder="Select date and time"
                        format="dd/MM/yyyy HH:mm:ss"
                        :readonly="true"
                      >
                      </el-date-picker>
                    </template>
                  </el-table-column>

                  <el-table-column label="Next Reset" width="250px">
                    <template slot-scope="{ row }">
                      <el-date-picker
                        v-model="row.DisplayEventStatus.NextReset"
                        type="datetime"
                        placeholder="Select date and time"
                        format="dd/MM/yyyy HH:mm:ss"
                        :readonly="true"
                      >
                      </el-date-picker>
                    </template>
                  </el-table-column>

                  <el-table-column
                    label="Wait Before Reset (seconds)"
                    width="150px"
                  >
                    <template slot-scope="{ row }">
                      <span>{{ row.EventStatus.IntervalBeforeReset }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-card>
          </el-tab-pane>

          <el-tab-pane label="LeaderBoard EndLess" name="1_2">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>LeaderBoard Settings</span>
              </div>
              <div>
                <el-table
                  :data="LeaderBoardSettingsList_EndLess"
                  style="width: 100%"
                >
                  <el-table-column label="ID" width="50px">
                    <template slot-scope="{ row }">
                      <span>{{ row.id }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column label="Name" width="120px">
                    <template slot-scope="{ row }">
                      <span>{{ row.data.name }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column label="EventID" width="120px">
                    <template slot-scope="{ row }">
                      <template v-if="row.editMode">
                        <el-input
                          v-model="row.data.EventInfo.eventID"
                          class="edit-input"
                          size="small"
                        />
                      </template>
                      <span v-else>{{ row.data.EventInfo.eventID }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column label="EventName" width="120px">
                    <template slot-scope="{ row }">
                      <template v-if="row.editMode">
                        <el-input
                          v-model="row.data.EventInfo.eventName"
                          class="edit-input"
                          size="small"
                        />
                      </template>
                      <span v-else>{{ row.data.EventInfo.eventName }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column label="Date" width="220px">
                    <template slot-scope="{ row }">
                      <template v-if="row.editMode">
                        From:
                        <el-date-picker
                          v-model="row.dataDisplay.EventInfo.startDate"
                          type="datetime"
                          placeholder="Select date and time"
                          format="dd/MM/yyyy HH:mm:ss"
                        >
                        </el-date-picker>
                        <br /><br />
                        To:
                        <el-date-picker
                          v-model="row.dataDisplay.EventInfo.endDate"
                          type="datetime"
                          placeholder="Select date and time"
                          format="dd/MM/yyyy HH:mm:ss"
                        >
                        </el-date-picker>
                      </template>
                      <span v-else
                        >From:
                        {{
                          row.dataDisplay.EventInfo.startDate.toLocaleString(
                            "en-GB", { hour12: false }
                          )
                        }}
                        <br /><br />
                        To:
                        {{
                          row.dataDisplay.EventInfo.endDate.toLocaleString(
                            "en-GB", { hour12: false }
                          )
                        }}</span
                      >
                      <br />
                    </template>
                  </el-table-column>

                  <el-table-column label="Groups" width="650px">
                    <template slot-scope="{ row }">
                      <!--<span>{{ row.editMode }}</span>-->
                      <template v-if="row.editMode">
                        <leader-board-group-list
                          v-bind:propID="row.id"
                          v-bind:propData="row.data.Groups"
                          v-bind:propEditMode="row.editMode"
                          v-on:CallbackChangeData="CallbackChangeGroupData"
                        ></leader-board-group-list>
                      </template>
                      <span v-else>
                        <leader-board-group-list
                          v-bind:propID="row.id"
                          v-bind:propData="row.data.Groups"
                          v-bind:propEditMode="row.editMode"
                          v-on:CallbackChangeData="CallbackChangeGroupData"
                        ></leader-board-group-list>
                      </span>
                    </template>
                  </el-table-column>

                  <!-- Actions for rewards -->
                  <el-table-column label="Actions" width="100px">
                    <template slot-scope="{ row }">
                      <template v-if="row.editMode">
                        <template v-if="row.updateMode">
                          <el-button
                            type="success"
                            size="small"
                            icon="el-icon-edit-outline"
                            disabled
                            @click="confirmEdit(row)"
                            >Update</el-button
                          >
                          <br />
                          <el-button
                            class="cancel-btn"
                            size="small"
                            icon="el-icon-refresh"
                            type="warning"
                            disabled
                            @click="cancelEdit(row)"
                            >Cancel</el-button
                          >
                        </template>
                        <template v-else>
                          <el-button
                            type="success"
                            size="small"
                            icon="el-icon-edit-outline"
                            @click="confirmEdit(row)"
                            >Update</el-button
                          >
                          <br />
                          <br />
                          <el-button
                            class="cancel-btn"
                            size="small"
                            icon="el-icon-refresh"
                            type="warning"
                            @click="cancelEdit(row)"
                            >Cancel</el-button
                          >
                        </template>
                      </template>
                      <template v-else>
                        <el-button
                          type="primary"
                          size="small"
                          icon="el-icon-edit"
                          @click="row.editMode = true"
                          >Edit</el-button
                        >
                        <br />
                        <br />
                        <el-button
                          type="danger"
                          size="small"
                          icon="el-icon-delete"
                          @click="onDelete(row)"
                          >Delete</el-button
                        >
                        <br />
                        <br />
                        <el-button
                          type="info"
                          size="small"
                          icon="el-icon-download"
                          @click="onExport(row)"
                          >Export</el-button
                        >
                      </template>
                    </template>
                  </el-table-column>

                  <el-table-column label="Rewards" width="1850px">
                    <template slot-scope="{ row }">
                      <!--<span>{{ row.editMode }}</span>-->
                      <template v-if="row.editMode">
                        <leader-board-reward-list
                          v-bind:propID="row.id"
                          v-bind:propData="row.data.Rewards"
                          v-bind:propEditMode="row.editMode"
                          v-on:CallbackChangeData="CallbackChangeRewardData"
                        ></leader-board-reward-list>
                      </template>
                      <span v-else>
                        <leader-board-reward-list
                          v-bind:propID="row.id"
                          v-bind:propData="row.data.Rewards"
                          v-bind:propEditMode="row.editMode"
                          v-on:CallbackChangeData="CallbackChangeRewardData"
                        ></leader-board-reward-list>
                      </span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-card>

            <br />

            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>LeaderBoard Status</span>
              </div>
              <div>
                <el-table
                  :data="LeaderBoardServiceList_EndLess"
                  style="width: 100%"
                >
                  <el-table-column label="Name" width="250px">
                    <template slot-scope="{ row }">
                      <span>{{ row.AppID }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column label="Status" width="150px">
                    <template slot-scope="{ row }">
                      <span>{{ row.EventStatus.Status }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column label="Current Time" width="250px">
                    <template slot-scope="{ row }">
                      <el-date-picker
                        v-model="row.DisplayEventStatus.CurrentTime"
                        type="datetime"
                        placeholder="Select date and time"
                        format="dd/MM/yyyy HH:mm:ss"
                        :readonly="true"
                      >
                      </el-date-picker>
                    </template>
                  </el-table-column>

                  <el-table-column label="Next Reset" width="250px">
                    <template slot-scope="{ row }">
                      <el-date-picker
                        v-model="row.DisplayEventStatus.NextReset"
                        type="datetime"
                        placeholder="Select date and time"
                        format="dd/MM/yyyy HH:mm:ss"
                        :readonly="true"
                      >
                      </el-date-picker>
                    </template>
                  </el-table-column>

                  <el-table-column
                    label="Wait Before Reset (seconds)"
                    width="150px"
                  >
                    <template slot-scope="{ row }">
                      <span>{{ row.EventStatus.IntervalBeforeReset }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-card>
          </el-tab-pane>

          <el-tab-pane label="Import" name="1_3">
            <el-card class="box-card">
              <span>
                <el-upload
                  class="upload-demo"
                  action=""
                  ref="upload"
                  :auto-upload="false"
                  :multiple="false"
                  :limit="1"
                  :on-change="OnHandleImportSettingsFile"
                >
                  <el-button slot="trigger" size="small" type="primary"
                    >Select A File To Import ...</el-button
                  >
                </el-upload>
              </span>
            </el-card>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>

      <!-- LeaderBoard Add -->
      <el-tab-pane label="LeaderBoard Add" name="2">
        <el-form ref="form" :model="leaderBoardItem" label-width="120px">
          <el-form-item label="Name" style="width: 40%">
            <el-input
              v-model="leaderBoardItem.name"
              class="edit-input"
              placeholder="LeaderBoard Name. Example: zs_leadeboard_event_modern_war"
            />
          </el-form-item>

          <!-- Add Leader Board Groups -->
          <el-form-item>
            <el-card class="box-card" style="min-height: 150px">
              <div slot="header" class="clearfix">
                <span>Events</span>
              </div>

              <el-form-item label="Auto Fill Event (LBQ, EndLess)">
                <el-select
                  v-model="LeaderBoardItem_EventID_AutoFill"
                  value-key="LeaderBoardItem_EventID_AutoFill"
                  filterable
                  allow-create
                  placeholder="Select"
                  @change="onEventIDChange($event)"
                  style="width: 16%"
                >
                  <el-option
                    v-for="item in LeaderBoardItem_EventIDList"
                    :key="item.eventID"
                    :label="item.eventID"
                    :value="item.eventID"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <br />

              <el-form-item label="Event Type">
                <el-select
                  v-model="LeaderBoardItem_EventType"
                  value-key="LeaderBoardItem_EventType"
                  filterable
                  allow-create
                  placeholder="Select"
                  @change="onEventTypeChange($event)"
                  style="width: 16%"
                >
                  <el-option
                    v-for="item in LeaderBoardItem_EventTypeList"
                    :key="item.eventType"
                    :label="item.eventType"
                    :value="item.eventType"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <br />

              <el-form-item label="Event ID" style="width: 40%">
                <el-input
                  v-model="LeaderBoardItem_EventID"
                  class="edit-input"
                  placeholder="Event ID. Example: 202101"
                  @change="OnChangeEventID($event)"
                />
              </el-form-item>
              <br />

              <el-form-item label="EventName" style="width: 40%">
                <el-input
                  v-model="LeaderBoardItem_EventName"
                  class="edit-input"
                  placeholder="Event Name. Example: Event XMas"
                  @change="OnChangeEventName($event)"
                />
              </el-form-item>
              <br />
              <el-form-item label="Begin Time">
                <el-date-picker
                  v-model="LeaderBoardItem_EventDate_Begin"
                  type="datetime"
                  placeholder="Select date and time"
                  @change="OnChangeGroupBeginDate($event)"
                >
                </el-date-picker>
              </el-form-item>
              <br />
              <el-form-item label="End Time">
                <el-date-picker
                  v-model="LeaderBoardItem_EventDate_End"
                  type="datetime"
                  placeholder="Select date and time"
                  @change="OnChangeGroupEndDate($event)"
                >
                </el-date-picker>
              </el-form-item>
              <br />
              <el-form-item
                label="Lock Interval Before End (Seconds)"
                style="width: 16%"
              >
                <el-input
                  v-model="LeaderBoardItem_EventDate_Lock_Before_End"
                  class="edit-input"
                  placeholder=""
                />
              </el-form-item>
            </el-card>
          </el-form-item>

          <!-- Add Leader Board Groups -->
          <el-form-item>
            <el-card class="box-card" style="min-height: 150px">
              <div slot="header" class="clearfix">
                <span>Groups</span>
              </div>

              <el-form-item label="OS">
                <el-select
                  v-model="LeaderBoardItem_Groups_OS"
                  value-key="LeaderBoardItem_Groups_OS"
                  filterable
                  allow-create
                  placeholder="Select"
                  @change="onGroupOSChange($event)"
                  style="width: 20%"
                >
                  <el-option
                    v-for="item in LeaderBoardItem_Groups_OSList"
                    :key="item.osName"
                    :label="item.osName"
                    :value="item.osName"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <br />

              <el-form-item label="Type">
                <el-select
                  v-model="LeaderBoardItem_Groups_Type"
                  value-key="LeaderBoardItem_Groups_Type"
                  filterable
                  allow-create
                  placeholder="Select"
                  @change="onGroupTypeChange($event)"
                  style="width: 20%"
                >
                  <el-option
                    v-for="item in LeaderBoardItem_Groups_TypeList"
                    :key="item.TypeName"
                    :label="item.TypeName"
                    :value="item.TypeName"
                  >
                  </el-option>
                </el-select>

                <template v-if="LeaderBoardItem_Groups_Type.includes('us')">
                  <span>
                    <br />(all users in the tier1 countries: US, GB, FR, DE, IT,
                    AU, CA, KR, JP, TW, HK)
                  </span>
                </template>

                <template v-if="LeaderBoardItem_Groups_Type.includes('tier3')">
                  <span>
                    <br />(all users in the tier3 countries: IN, PH, ID)
                  </span>
                </template>
              </el-form-item>
              <br />

              <el-form-item label="Count" style="width: 20%">
                <el-input
                  v-model="LeaderBoardItem_Groups_Count"
                  class="edit-input"
                  placeholder=""
                />
              </el-form-item>
              <br />

              <el-form-item label="Payment Size" style="width: 20%">
                <el-input
                  v-model="LeaderBoardItem_Groups_Payment_Size"
                  class="edit-input"
                  placeholder="Payment Size <= Size"
                />
              </el-form-item>
              <br />

              <el-form-item
                label="None Payment (Fast Fill Board)"
                style="width: 20%"
              >
                <el-input
                  v-model="LeaderBoardItem_Groups_None_Payment_Limit_Size_1"
                  class="edit-input"
                  placeholder="None Payment Limit Size <= Size"
                />
              </el-form-item>
              <br />

              <el-form-item label="Total Size" style="width: 20%">
                <el-input
                  v-model="LeaderBoardItem_Groups_Size"
                  class="edit-input"
                  placeholder=""
                />
              </el-form-item>
              <br />

              <el-form-item>
                <el-button
                  type="success"
                  size="small"
                  icon="el-icon-edit-outline"
                  @click="OnLeaderBoardItem_Groups_Add()"
                  >Add Group</el-button
                >

                <el-button
                  type="success"
                  size="small"
                  icon="el-icon-edit-outline"
                  @click="OnLeaderBoardItem_Groups_Delete()"
                  >Delete Group</el-button
                >
              </el-form-item>

              <leader-board-group-list
                v-bind:propData="leaderBoardItem.Groups"
                v-bind:propEditMode="false"
                v-on:CallbackChangeData="CallbackChangeGroupData"
              ></leader-board-group-list>
            </el-card>
          </el-form-item>

          <!-- Add Leader Board Rewards -->
          <el-form-item>
            <el-card class="box-card" style="min-height: 150px">
              <div slot="header" class="clearfix">
                <span>Rewards</span>
              </div>

              <el-card class="box-card" style="min-height: 100px">
                <div slot="header" class="clearfix">
                  <span>Import Rank Rewards</span>
                </div>
                <!-- Handle Import Reward from a file -->
                <el-form-item
                  label="Import Rank Rewards From File (.mlb) "
                  label-width="300px"
                >
                  <el-upload
                    class="upload-demo"
                    action=""
                    ref="upload"
                    :auto-upload="false"
                    :multiple="false"
                    :limit="1"
                    :on-change="OnHandleRewardFileUpload"
                  >
                    <el-button slot="trigger" size="small" type="primary"
                      >...</el-button
                    >
                  </el-upload>
                </el-form-item>
              </el-card>

              <br />

              <el-card class="box-card" style="min-height: 100px">
                <div slot="header" class="clearfix">
                  <span>Rewards List</span>
                </div>
                <leader-board-reward-list
                  v-bind:propData="leaderBoardItem.Rewards"
                  v-bind:propEditMode="false"
                  v-on:CallbackChangeData="CallbackChangeRewardData"
                ></leader-board-reward-list>
              </el-card>

              <br />

              <el-card class="box-card" style="min-height: 100px">
                <div slot="header" class="clearfix">
                  <span>Add/Delete Rank Rewards</span>
                </div>

                <el-form-item label="ID" style="width: 20%">
                  <el-input
                    v-model="LeaderBoardItem_Rewards_ID"
                    class="edit-input"
                    placeholder=""
                  />
                </el-form-item>
                <br />
                <el-form-item label="Title">
                  <el-input
                    v-model="LeaderBoardItem_Rewards_Title"
                    class="edit-input"
                    placeholder="Reward Title. Example: Reward_Rank_1_10"
                  />
                </el-form-item>
                <br />
                <el-form-item label="Rank Min" style="width: 20%">
                  <el-input
                    v-model="LeaderBoardItem_Rewards_RankMin"
                    class="edit-input"
                    placeholder=""
                  />
                </el-form-item>
                <br />
                <el-form-item label="Rank Max" style="width: 20%">
                  <el-input
                    v-model="LeaderBoardItem_Rewards_RankMax"
                    class="edit-input"
                    placeholder=""
                  />
                </el-form-item>
                <br />
                <el-form-item label="Require Point" style="width: 20%">
                  <el-input
                    v-model="LeaderBoardItem_Rewards_RequirePoint"
                    class="edit-input"
                    placeholder=""
                  />
                </el-form-item>
                <br />
                <el-form-item label="Gifts">
                  <el-input
                    v-model="LeaderBoardItem_Rewards_Gifts"
                    class="edit-input"
                    :disabled="true"
                    :readonly="true"
                    placeholder=""
                  />
                </el-form-item>
                <br />

                <div style="padding-left: 120px; padding-bottom: 20px">
                  <add-reward
                    v-model="LeaderBoardItem_Rewards"
                    v-on:CallbackChangeData="OnLeaderBoardItem_Rewards_Change"
                  />
                </div>

                <el-form-item>
                  <el-button
                    type="success"
                    size="small"
                    icon="el-icon-edit-outline"
                    @click="OnLeaderBoardItem_Rewards_Add()"
                    >Add Rank Rewards</el-button
                  >

                  <el-button
                    type="success"
                    size="small"
                    icon="el-icon-edit-outline"
                    @click="OnLeaderBoardItem_Rewards_Delete()"
                    >Delete Rank Rewards</el-button
                  >
                </el-form-item>
              </el-card>
            </el-card>
          </el-form-item>

          <el-form-item>
            <el-button
              type="success"
              size="small"
              icon="el-icon-edit-outline"
              @click="OnLeaderBoardItem_Add()"
              >Add Leader Board</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>

      <el-tab-pane label="LeaderBoard User" name="3">
        <el-row :gutter="20">
          <el-col :span="15">
            <el-card class="box-card" style="min-height: 468px">
              <div slot="header" class="clearfix">
                <span>User Data</span>
              </div>

              <el-form ref="form" :model="UserInfo" label-width="120px">
                <el-form-item label="Application ID">
                  <!-- Select application -->
                  <el-select
                    v-model="SelectedApplicationID"
                    value-key="SelectedApplicationID"
                    filterable
                    allow-create
                    placeholder="Select"
                    @change="onAppIDChange($event)"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in listAppInfos"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="UserID">
                  <el-input
                    v-model="UserInfo.UserID"
                    placeholder="UserID"
                    width="50px"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Group Name">
                  <el-input
                    v-model="UserInfo.GroupName"
                    placeholder="Group Name"
                    width="50px"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Score">
                  <el-input
                    v-model="UserScore.score"
                    placeholder="Score"
                    width="50px"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Rank">
                  <el-input
                    v-model="UserScore.rank"
                    placeholder="Rank"
                    width="50px"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Information">
                  <el-input
                    v-model="this.UserInfo.Info"
                    placeholder="Information"
                    width="50px"
                    type="textarea"
                    rows="5"
                  ></el-input>
                </el-form-item>

                <el-form-item>
                  <el-button type="primary" size="small" @click="onGetUserData"
                    >Get User Data</el-button
                  >
                </el-form-item>

                <el-card class="box-card" style="min-height: 128px">
                  <div slot="header" class="clearfix">
                    <span>Move User To Group</span>
                  </div>

                  <el-form-item>
                    <el-select
                      v-model="SelectedGroupName"
                      value-key="SelectedGroupName"
                      filterable
                      allow-create
                      placeholder="Select Group"
                      @change="onGroupnameChange($event)"
                      style="width: 65%"
                    >
                      <el-option
                        v-for="item in listGroupNames"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>

                    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;

                    <el-button
                      type="primary"
                      size="small"
                      @click="onChangeUserGroup"
                      >Move</el-button
                    >
                  </el-form-item>
                </el-card>

                <br />

                <el-card class="box-card" style="min-height: 128px">
                  <div slot="header" class="clearfix">
                    <span>Restore Cheat User To Normal Group</span>
                    &emsp;&emsp;
                    <el-button
                      type="primary"
                      size="small"
                      @click="onRestoreCheatUserGroupToNomalGroup"
                      >Move</el-button
                    >
                  </div>
                </el-card>
              </el-form>
            </el-card>
          </el-col>
        </el-row>
      </el-tab-pane>

      <el-tab-pane label="LeaderBoard Status" name="4">
        <el-row :gutter="20">
          <!-- Service Data -->
          <el-col :span="7">
            <el-card class="box-card" style="min-height: 448px">
              <div slot="header" class="clearfix">
                <span>Service Data Run Time</span>
              </div>

              <el-form ref="form" :model="ServiceData" label-width="120px">
                <el-form-item label="Application ID">
                  <!-- Select application -->
                  <el-select
                    v-model="SelectedApplicationID"
                    value-key="SelectedApplicationID"
                    filterable
                    allow-create
                    placeholder="Select"
                    @change="onAppIDChange($event)"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in listAppInfos"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="Status">
                  <el-input
                    v-model="ServiceData.Status"
                    placeholder="Status=STARTED/STOPPED/LOCKED"
                    width="50px"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Next Reset">
                  <el-date-picker
                    v-model="ServiceData.NextReset"
                    type="datetime"
                    placeholder="Select date and time"
                  >
                  </el-date-picker>
                </el-form-item>

                <el-form-item label="Wait For Reset">
                  <el-input
                    v-model="ServiceData.IntervalBeforeReset"
                    placeholder="IntervalBeforeReset= xxx in seconds"
                    width="50px"
                  ></el-input>
                </el-form-item>

                <el-form-item>
                  <el-button type="primary" size="small" @click="onGetEventData"
                    >Get Event Data</el-button
                  >
                  <el-button type="primary" size="small" @click="onSetEventData"
                    >Set Event Data</el-button
                  >
                </el-form-item>
              </el-form>
            </el-card>
          </el-col>

          <!-- leadboards information -->
          <el-col :span="15">
            <el-card class="box-card" style="min-height: 448px">
              <div slot="header" class="clearfix">
                <span>LeaderBoards Information</span>
              </div>

              <el-row :gutter="20">
                <el-col :span="10">
                  <el-row :gutter="10">
                    <el-button
                      type="primary"
                      size="small"
                      @click="onAllLeaderBoardInfo"
                      >Refresh</el-button
                    >
                  </el-row>

                  <br />

                  <el-row :gutter="10">
                    <el-input
                      type="textarea"
                      v-model="LeaderBoardData.Info"
                      :readonly="true"
                      :rows="12"
                    ></el-input>
                  </el-row>
                </el-col>

                <el-col :span="12">
                  <el-row :gutter="10">
                    <el-col :span="4"> Top Name: </el-col>
                    <el-col :span="8">
                      <el-input
                        v-model="LeaderBoardData.CurrentTopName"
                        width="50px"
                      ></el-input>
                    </el-col>
                    <el-col :span="12">
                      <el-button
                        type="primary"
                        size="small"
                        @click="onLeaderBoardTop"
                        >Show Top</el-button
                      >
                      <el-button
                        type="primary"
                        size="small"
                        @click="onLeaderBoardFindTopNext"
                        >Find Top Next</el-button
                      >
                    </el-col>
                  </el-row>

                  <br />

                  <el-row :gutter="10">
                    <el-input
                      type="textarea"
                      v-model="LeaderBoardData.CurrentTopInfo"
                      :readonly="true"
                      :rows="12"
                    ></el-input>
                  </el-row>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>

        <br />

        <!-- Get and Set Event Data -->
        <el-row :gutter="20">
          <!-- cheat time -->
          <el-col :span="8">
            <el-card class="box-card" style="min-height: 468px">
              <div slot="header" class="clearfix">
                <span>Cheat LeaderBoard Time</span>
              </div>
              <el-form ref="form" :model="cheatTimeData" label-width="120px">
                <el-form-item label="Current">
                  <el-input
                    v-model="cheatTimeData.Current"
                    :readonly="true"
                  ></el-input>
                </el-form-item>
                <el-form-item label="+ days">
                  <el-input
                    v-model="cheatTimeData.Days"
                    :disabled="false"
                  ></el-input>
                </el-form-item>
                <el-form-item label="+ hours">
                  <el-input
                    v-model="cheatTimeData.Hours"
                    :disabled="false"
                  ></el-input>
                </el-form-item>
                <el-form-item label="+ mins">
                  <el-input
                    v-model="cheatTimeData.Mins"
                    :disabled="false"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Result Time: ">
                  <el-input
                    v-model="cheatTimeData.ResultTime"
                    :disabled="true"
                  ></el-input>
                </el-form-item>

                <el-form-item>
                  <el-button
                    type="primary"
                    size="small"
                    @click="onViewResultTime"
                    >View Result Time</el-button
                  >
                  <el-button
                    type="primary"
                    size="small"
                    @click="onSaveResultTime"
                    >Save Result Time</el-button
                  >
                </el-form-item>
              </el-form>
            </el-card>
          </el-col>

          <el-col :span="5">
            <el-card class="box-card" style="min-height: 468px">
              <div slot="header" class="clearfix">
                <span>Import User Data for Testing</span>
              </div>

              <!-- Handle Import User List from a file -->
              <template>
                <span>Import User Data from a File (.txt):</span>
                <el-upload
                  class="upload-demo"
                  action=""
                  ref="upload"
                  :auto-upload="false"
                  :multiple="false"
                  :limit="1"
                  :on-change="OnHandleImportUser"
                >
                  <el-button slot="trigger" size="small" type="primary"
                    >...</el-button
                  >
                </el-upload>
              </template>

              <br />
              <br />
              <br />

              <template>
                <span>Import User Score from a File (.txt):</span>
                <el-upload
                  class="upload-demo"
                  action=""
                  ref="upload"
                  :auto-upload="false"
                  :multiple="false"
                  :limit="1"
                  :on-change="OnHandleImportUserScore"
                >
                  <el-button slot="trigger" size="small" type="primary"
                    >...</el-button
                  >
                </el-upload>
              </template>
            </el-card>
          </el-col>

          <el-col :span="5">
            <el-card class="box-card" style="min-height: 468px">
              <div slot="header" class="clearfix">
                <span>Testing Users</span>
              </div>

              <el-row :gutter="20">
                <el-input
                  type="textarea"
                  v-model="TestingUserInfo"
                  :readonly="true"
                  :rows="15"
                ></el-input>
              </el-row>
            </el-card>
          </el-col>
        </el-row>

        <br />

        <!-- Restore Data -->
        <el-row :gutter="20">
          <el-col :span="8">
            <el-card class="box-card" style="min-height: 208px">
              <div slot="header" class="clearfix">
                <span>Restore from Backup Data</span>
              </div>

              <el-form ref="form" label-width="120px">
                <el-form-item label="Backup Key">
                  <el-input
                    v-model="BackupKey"
                    class="edit-input"
                    placeholder="Backup Key=YYYYmmdd_xxxxxx"
                  />

                  <el-button
                    type="primary"
                    size="small"
                    @click="onRestoreDataFromBackup"
                    >Restore Data</el-button
                  >
                </el-form-item>
              </el-form>
            </el-card>
          </el-col>
        </el-row>
      </el-tab-pane>

      <el-tab-pane label="LeaderBoard Logs" name="5">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-card class="box-card" style="min-height: 200px">
              <div slot="header" class="clearfix">
                <span>Leaderboard Old Backups</span>
              </div>

              <el-button
                type="success"
                size="small"
                icon="el-icon-edit-outline"
                @click="Statistic_GetOldBackupListFile()"
                >Refresh</el-button
              >

              <el-table
                :data="oldBackupFileData"
                style="width: 100%"
                max-height="490"
              >
                <el-table-column label=".No" width="50px">
                  <template slot-scope="{ row }">
                    <span>{{ row.id }}</span>
                  </template>
                </el-table-column>

                <el-table-column label="File Name" width="550px">
                  <template slot-scope="{ row }">
                    <span>{{ row.fileName }}</span>
                  </template>
                </el-table-column>

                <el-table-column label="Links">
                  <template slot-scope="{ row }">
                    (<a v-bind:href="row.link">Link</a>)
                  </template>
                </el-table-column>
              </el-table>
            </el-card>
          </el-col>

          <el-col :span="10">
            <el-row :gutter="20">
              <el-form ref="form" label-width="120px">
                <el-card class="box-card" style="min-height: 200px">
                  <div slot="header" class="clearfix">
                    <span>Download Logs</span>
                  </div>

                  <div>
                    <el-form-item label="Application: ">
                      <el-select
                        v-model="SelectedApplicationID"
                        value-key="SelectedApplicationID"
                        filterable
                        allow-create
                        placeholder="Select"
                        @change="onAppIDChange($event)"
                        style="width: 60%"
                      >
                        <el-option
                          v-for="item in listAppInfos"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                      <span style="display: inline-block; width: 20px"></span>
                      <el-button
                        type="primary"
                        size="small"
                        @click="onGetDownloadLinks"
                        >Get Download Links</el-button
                      >
                    </el-form-item>

                    <el-form-item label="Links: ">
                      <div
                        v-for="linkItem in downloadLogLinks"
                        :key="linkItem.value"
                        class="text item"
                      >
                        <el-link :href="`${linkItem.value}`" target="_blank">{{
                          linkItem.label
                        }}</el-link>
                      </div>
                    </el-form-item>
                  </div>

                  <el-card class="box-card" style="min-height: 200px">
                    <div slot="header" class="clearfix">
                      <span>Download Last Reward Logs</span>
                    </div>

                    <div>
                      <el-button
                        type="success"
                        size="small"
                        icon="el-icon-edit-outline"
                        @click="Statistic_GetLastRewardLogFile()"
                        >Refresh</el-button
                      >

                      <el-table
                        :data="logRewardFileData"
                        style="width: 100%"
                        max-height="490"
                      >
                        <el-table-column label=".No" width="50px">
                          <template slot-scope="{ row }">
                            <span>{{ row.id }}</span>
                          </template>
                        </el-table-column>

                        <el-table-column label="File Name" width="400px">
                          <template slot-scope="{ row }">
                            <span>{{ row.fileName }}</span>
                          </template>
                        </el-table-column>

                        <el-table-column label="Links">
                          <template slot-scope="{ row }">
                            (<a v-bind:href="row.link">Link</a>)
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </el-card>
                </el-card>

                <br />
              </el-form>
            </el-row>

            <el-row :gutter="20">
              <el-form ref="form" label-width="120px">
                <el-card class="box-card" style="min-height: 200px">
                  <div slot="header" class="clearfix">
                    <span>Download Current Error Logs</span>
                  </div>

                  <div>
                    <el-button
                      type="success"
                      size="small"
                      icon="el-icon-edit-outline"
                      @click="Statistic_GetErrorLogFile()"
                      >Refresh</el-button
                    >

                    <el-table
                      :data="logErrorFileData"
                      style="width: 100%"
                      max-height="490"
                    >
                      <el-table-column label=".No" width="50px">
                        <template slot-scope="{ row }">
                          <span>{{ row.id }}</span>
                        </template>
                      </el-table-column>

                      <el-table-column label="File Name" width="450px">
                        <template slot-scope="{ row }">
                          <span>{{ row.fileName }}</span>
                        </template>
                      </el-table-column>

                      <el-table-column label="Links">
                        <template slot-scope="{ row }">
                          (<a v-bind:href="row.link">Link</a>)
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-card>

                <br />
              </el-form>
            </el-row>

            <el-row :gutter="20">
              <el-form ref="form" label-width="120px">
                <el-card
                  class="box-card"
                  style="min-height: 150px; width: 100%"
                >
                  <div slot="header" class="clearfix">
                    <span>Leaderboard Cheat Log</span>
                  </div>

                  <el-button
                    type="success"
                    size="small"
                    icon="el-icon-edit-outline"
                    @click="Statistic_OnLeaderBoardRefreshCheatLog()"
                    >Refresh</el-button
                  >

                  <el-table
                    :data="cheatLogFileData"
                    style="width: 100%"
                    max-height="990"
                  >
                    <el-table-column label=".No" width="50px">
                      <template slot-scope="{ row }">
                        <span>{{ row.id }}</span>
                      </template>
                    </el-table-column>

                    <el-table-column label="File Name" width="400px">
                      <template slot-scope="{ row }">
                        <span>{{ row.fileName }}</span>
                      </template>
                    </el-table-column>

                    <el-table-column label="Links">
                      <template slot-scope="{ row }">
                        (<a v-bind:href="row.link">Link</a>)
                      </template>
                    </el-table-column>
                  </el-table>
                </el-card>
              </el-form>
            </el-row>
          </el-col>
        </el-row>

        <el-row :gutter="20"> </el-row>
      </el-tab-pane>

      <!-- Leader board Black List -->
      <el-tab-pane label="LeaderBoard Black List" name="6">
        <el-form ref="form" :model="BlackListUser" label-width="120px">
          <el-form-item label="ApplicationID">
            <el-input
              v-model="SelectedApplicationID"
              :disabled="false"
            ></el-input>
          </el-form-item>

          <el-form-item label="UserID">
            <el-input
              v-model="BlackListUser.userID"
              :disabled="false"
            ></el-input>
          </el-form-item>

          <el-form-item label="Description">
            <el-input
              v-model="BlackListUser.description"
              :disabled="false"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" size="small" @click="onSetBlackListUser"
              >Set Back List</el-button
            >
            <el-button type="primary" size="small" @click="onUnsetBlackListUser"
              >Unset Back List</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="onRefreshBlackListUser"
              >Refresh</el-button
            >
          </el-form-item>

          <!-- Handle Import Black List from a file -->
          <!--
          <el-form-item label="Import Black List From File (.csv) " label-width="300px">
            <el-upload
              class="upload-demo"
              action=""
              ref="upload"              
              :auto-upload="false"
              :multiple="false"
              :limit="1"
              :on-change="OnHandleImportBlackList"
              >                  
              <el-button slot="trigger" size="small" type="primary">...</el-button>                            
            </el-upload>
          </el-form-item>
          -->
        </el-form>

        <el-table :data="BlackListDisplayList" style="width: 100%">
          <el-table-column label="ID" width="50px">
            <template slot-scope="{ row }">
              <span>{{ row.idx }}</span>
            </template>
          </el-table-column>
          <el-table-column label="UserID" width="250px">
            <template slot-scope="{ row }">
              <span>{{ row.userID }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Description" width="450px">
            <template slot-scope="{ row }">
              <span>{{ row.description }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>


<script>
import { MessageBox, Message } from "element-ui";
import { Loading } from "element-ui";
import { isStringEmpty } from "@/utils/validate";
import { getToken, getOSToken } from "@/utils/auth";

import AddReward from "@/components/AddReward";
import LeaderBoardGroupList from "@/components/CompLeaderBoardGroupList";
import LeaderBoardRewardList from "@/components/CompLeaderBoardRewardList";

import {
  getCheatTime,
  setCheatTime,
  getBlackList,
  setBlackListUser,
  getApplicationInfos,
  UploadFileToRemoteCDN,
  UploadFileStatus,
  HelperGetDownloadLinks,
  HelperRestoreDataFromBackup,
  HelperGetEventData,
  HelperGetEventDataList,
  HelperSetEventData,
  HelperGetLeaderBoardAllSettings,
  HelperGetAllLeaderBoardInfo,
  HelperSetLeaderBoardAllSettings,
  HelperApplyLeaderBoardAllSettings,
  HelperApplyLeaderBoardAllSettingsPolling,
  HelperGetStatusPolling,
  HelperLeaderBoard_GetOldBackupFileList,
  HelperLeaderBoard_GetCurrentErrorLogFileList,
  HelperLeaderBoard_GetLastRewardLogFileList,
  HelperLeaderboard_GetCheatLogFileList,
  TryParseUploadFile_ReadTextFile,
  HelperParseRewardFromFile,
  HelperTryAddTestUsers,
  HelperTryAddTestUserScores,
  HelperApplyEventTime,
  HelperGetTopUser,
  HelperGetUserData,
  HelperChangeUserGroup,
  HelperRestoreCheatUserGroupToNomalGroup,
  HelperGetTestingUserList,
  DownloadJS,
} from "@/api/gameservice";

import { getEventDataFromFirebase } from "@/api/dynamic_events";

// global timers
var cheatTimeRefreshTimer;
var LeaderBoardServiceList_Timer;

// Event Types
var EVENT_TYPE_ENDLESS = "EndLess";
var EVENT_TYPE_LBQ = "LBQ";

// Status for Apply Settings
const APPLY_STATUS_READY = "READY";
const APPLY_STATUS_ENABLE = "ENABLE";
const APPLY_STATUS_PROCESSING = "PROCESSING";
const APPLY_STATUS_DONE = "DONE";

export default {
  name: "GameServiceLBQ",
  components: { AddReward, LeaderBoardGroupList, LeaderBoardRewardList },
  data() {
    return {
      form: {
        displayName: "",
        codeName: "",
        desc: "",
      },
      cheatTimeData: {
        Current: "",
        Days: 0,
        Hours: 0,
        Mins: 0,
        ResultTime: "",
        cheatTimeOffset: 0,
      },
      SelectedApplicationID: "",
      SelectedGroupName: "",
      BackupKey: "",
      BlackListUser: { userID: "", description: "" },
      BlackList: [],
      BlackListDisplayList: [],
      loading: false,
      activeNames: "1",
      activeLeaderBoardTypes: "1_1",
      listAppInfos: [
        {
          value: "ZombieSurvival_ModernWar",
          label: "ZombieSurvival_ModernWar",
        },
        {
          value: "ZombieSurvival_ThanksGiving",
          label: "ZombieSurvival_ThanksGiving",
        },
      ],
      listGroupNames: [
        {
          //value: 'group1',
          //label: 'group2'
        },
      ],
      downloadLogLinks: [
        {
          value: "http://google.com",
          label: "Logs",
        },
      ],
      ServiceData: {
        Status: "STARTED",
        LastReset: 0,
        NextReset: 0,
        IntervalBeforeReset: 0,
        eventData: undefined,
      },
      //----------------------------------
      UserInfo: {
        UserID: "",
        GroupName: "",
        Info: "",
      },
      UserScore: {
        score: 0,
        rank: 0,
      },
      //----------------------------------
      LeaderBoardSettingsList: [],
      LeaderBoardSettingsList_LBQ: [],
      LeaderBoardSettingsList_EndLess: [],
      leaderBoardItem: {
        name: "leader_board_name",
        Groups: [],
        Rewards: [],
        EventInfo: {
          eventID: 0,
          eventType: EVENT_TYPE_LBQ,
          startDate: 0,
          endDate: 0,
        },
      },
      LeaderBoardItem_Groups_Name: "",
      LeaderBoardItem_Groups_OS: getOSToken().toLowerCase(), //"android",
      LeaderBoardItem_Groups_OSList: [{ osName: getOSToken().toLowerCase() }], //[{osName: "android"}],
      LeaderBoardItem_Groups_Type: "us",
      LeaderBoardItem_Groups_TypeList: [
        { TypeName: "us" },
        { TypeName: "others" },
        { TypeName: "cheaters" },
        { TypeName: "testers" },
      ],
      LeaderBoardItem_Groups_Count: 1,
      LeaderBoardItem_Groups_Size: 100,
      LeaderBoardItem_Groups_Payment_Size: 20,
      LeaderBoardItem_Groups_None_Payment_Limit_Size_1: 80,
      //----------------------------------
      LeaderBoardItem_Rewards_ID: 1,
      LeaderBoardItem_Rewards_Title: "reward1",
      LeaderBoardItem_Rewards_RankMin: 1,
      LeaderBoardItem_Rewards_RankMax: 10,
      LeaderBoardItem_Rewards_RequirePoint: 1,
      LeaderBoardItem_Rewards_Gifts: "",
      LeaderBoardItem_Rewards: [],
      //----------------------------------
      LeaderBoardItem_EventIDList: [],
      LeaderBoardItem_EventID: 0,
      LeaderBoardItem_EventID_AutoFill: 0,
      LeaderBoardItem_EventTypeList: [
        { eventType: EVENT_TYPE_LBQ },
        { eventType: EVENT_TYPE_ENDLESS },
      ],
      LeaderBoardItem_EventType: EVENT_TYPE_LBQ,
      LeaderBoardItem_EventName: "",
      LeaderBoardItem_EventDate_Begin: "",
      LeaderBoardItem_EventDate_End: "",
      LeaderBoardItem_EventDate_Lock_Before_End: 300,
      //----------------------------------
      LeaderBoardServiceList: [],
      LeaderBoardServiceList_LBQ: [],
      LeaderBoardServiceList_EndLess: [],
      LeaderBoardServiceItem: {
        Status: "STARTED",
        LastReset: 0,
        NextReset: 0,
        IntervalBeforeReset: 0,
        eventData: undefined,
      },
      //----------------------------------
      LeaderBoard_Apply_Settings_Status: "",
      LeaderBoard_Processing_Time_Inc: 0,

      // Apply Settings Statuses
      APPLY_STATUS_READY: APPLY_STATUS_READY,
      APPLY_STATUS_ENABLE: APPLY_STATUS_ENABLE,
      APPLY_STATUS_PROCESSING: APPLY_STATUS_PROCESSING,
      APPLY_STATUS_DONE: APPLY_STATUS_DONE,
      //----------------------------------
      LeaderBoard_Is_Enable_Redirect: false,
      LeaderBoard_Redirect_Message: "",
      //----------------------------------
      LeaderBoardData: {
        Info: "",
        AppID: "",
        TopNames: [],
        CurrentTopNameIndex: 0,
        CurrentTopName: "",
        CurrentTopInfo: "",
      },
      //----------------------------------
      TestingUserInfo: "",
      //----------------------------------
      //------------------------------------------------
      // Old Backup Files
      // Example:
      //    [{id:1, fileName: 'abc', link: 'link'}]
      //
      oldBackupFileData: [],
      //------------------------------------------------
      logErrorFileData: [],
      //------------------------------------------------
      logRewardFileData: [],
      //------------------------------------------------

      //------------------------------------------------
      cheatLogFileData: [],
      //------------------------------------------------
    };
  },
  created() {
    let currentTime = new Date();

    this.cheatTimeData.Current = currentTime.toString();

    // Get Application Information: ID, ...
    this.RefreshApplicationInfos(function (code) {});

    // Get Black List
    this.RefreshBlackList();

    this.DoGetCheatTime();

    setTimeout(() => this.onGetEventData(), 1000);

    this.onGetEventData();

    // get all leader board settings
    this.onGetLeaderBoardAllSetings();

    // Show all leaderboard runtime status
    this.OnGetLeaderBoardRuntimeStatusList();

    // Refesh leaderboard all information
    //this.onAllLeaderBoardInfo();

    this.GetTestingUser();
  },
  methods: {
    //---------------------------------------------------------
    onGetLeaderBoardAllSetings() {
      this.LeaderBoardItem_EventIDList = [];
      //---------------------------------------

      //---------------------------------------
      let beginDate = new Date();
      let endDate = new Date();
      //---------------------------------------

      this.getEventDataList();

      this.LeaderBoardSettingsList = [];
      this.LeaderBoardSettingsList_LBQ = [];
      this.LeaderBoardSettingsList_EndLess = [];

      HelperGetLeaderBoardAllSettings()
        .then((response) => {
          // Groups:
          //[
          //  {
          //    name: "prefix1_1",
          //    count: 1,
          //    size: 2
          //  },...
          //]

          //"EventInfo": {
          //      "eventID": 201201,
          //      "startDate": 0,
          //      "endDate": 0
          // }

          this.LeaderBoardSettingsList = [];
          this.LeaderBoardSettingsList_LBQ = [];
          this.LeaderBoardSettingsList_EndLess = [];

          let allSettings = response.data.settings;

          this.LeaderBoard_Apply_Settings_Status = response.data.status;

          // start a timer to checking status processing next status ...
          if (
            this.LeaderBoard_Apply_Settings_Status === APPLY_STATUS_PROCESSING
          ) {
            this.LeaderBoard_Processing_Time_Inc = 0;
            this.loading = true;
            setTimeout(() => this.onRecheckApplySettingsStatus(), 5000);
          }

          // redirect info
          let redirectInfo = response.data.redirectInfo;
          if (redirectInfo) {
            //{
            //RedirectEnable: 1,
            //RedirectVersion: "1.2.3",
            //RedirectToServer: "Dev",
            //RedirectToUrl: ""
            //}

            if (redirectInfo.RedirectEnable > 0) {
              this.LeaderBoard_Is_Enable_Redirect = true;

              this.LeaderBoard_Redirect_Message = `All Leaderboard Client Version "${redirectInfo.RedirectVersion}" Will Be Redirected To Server "${redirectInfo.RedirectToServer}" ${redirectInfo.RedirectToUrl}`;
            } else this.LeaderBoard_Is_Enable_Redirect = false;
          }
          //----------------------------------------------

          for (let i = 0; i < allSettings.length; i++) {
            let id = i + 1;

            let startDate = new Date(allSettings[i].EventInfo.startDate * 1000);
            let endDate = new Date(allSettings[i].EventInfo.endDate * 1000);

            let eventInfoTmp = {
              eventID: allSettings[i].EventInfo.eventID,
              eventType: allSettings[i].EventInfo.eventType,
              eventName: allSettings[i].EventInfo.eventName,
              startDate: allSettings[i].EventInfo.startDate,
              endDate: allSettings[i].EventInfo.endDate,
            };

            let eventInfoDisplay = {
              eventID: allSettings[i].EventInfo.eventID,
              eventType: allSettings[i].EventInfo.eventType,
              eventName: allSettings[i].EventInfo.eventName,
              startDate: startDate,
              endDate: endDate,
            };

            //let rewardsTmp = JSON.stringify(allSettings[i].Rewards);
            //Message({message: "Get All Settings Rewards: " + JSON.stringify(allSettings[i].Rewards), type: "error", duration: 5 * 1000});

            let tmpRewards = [];
            for (let j = 0; j < allSettings[i].Rewards.length; j++) {
              tmpRewards.push({
                id: allSettings[i].Rewards[j].id,
                //eventId: allSettings[i].Rewards[j].eventId,
                title: allSettings[i].Rewards[j].title,
                rankMin: allSettings[i].Rewards[j].rankMin,
                rankMax: allSettings[i].Rewards[j].rankMax,
                requiredPoint: allSettings[i].Rewards[j].requiredPoint,
                gifts: JSON.stringify(allSettings[i].Rewards[j].gifts),
              });
            }

            tmpRewards.sort(function (a, b) {
              return parseInt(a.id) - parseInt(b.id);
            });

            this.LeaderBoardSettingsList.push({
              id: id,
              data: {
                name: allSettings[i].name,
                Groups: allSettings[i].Groups,
                Rewards: tmpRewards,
                EventInfo: eventInfoTmp,
              },
              dataDisplay: {
                EventInfo: eventInfoDisplay,
              },
              editMode: false,
              updateMode: false,
            });
          } //for

          // sort list of settings
          this.LeaderBoardSettingsList.sort(function (a, b) {
            if (a.id > b.id) return -1;
            if (a.id < b.id) return 1;
            return 0;
          });

          for (let k = 0; k < this.LeaderBoardSettingsList.length; k++) {
            if (
              this.LeaderBoardSettingsList[k].data.EventInfo.eventType ===
              EVENT_TYPE_ENDLESS
            ) {
              this.LeaderBoardSettingsList_EndLess.push(
                this.LeaderBoardSettingsList[k]
              );
            } else {
              this.LeaderBoardSettingsList_LBQ.push(
                this.LeaderBoardSettingsList[k]
              );
            }
          }

          //For Add new leaderboard
          let currDate = new Date();

          //this.leaderBoardItem = this.LeaderBoardSettingsList[0].data;
          this.leaderBoardItem = {
            name: "",
            Groups: [],
            Rewards: [],
            EventInfo: {
              eventID: 0,
              eventType: "unknown",
              startDate: currDate,
              endDate: currDate,
            },
          };
        })
        .catch((error) => {
          Message({
            message: "Get All Settings Data: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    //------------------------------------------------------------------------------------
    // get config data list
    getEventDataList() {
      getEventDataFromFirebase()
        .then((response) => {
          let configEventDataList = [];

          this.LeaderBoardItem_EventIDList = [];
          //this.LeaderBoardItem_EventTypeList = [];

          if (response.events) {
            //Message({message: "Get getEventDataFromFirebase " + JSON.stringify(response.events), type: "success", duration: 5 * 1000});

            // events load from firebase have type 'LBQ'

            let eventTypeTmp = "";
            for (let idx = 0; idx < response.events.length; idx++) {
              if (response.events[idx].event_type === "EVENT_ENDLESS") {
                eventTypeTmp = EVENT_TYPE_ENDLESS;
              } else if (response.events[idx].event_type === "EVENT_LBQ") {
                eventTypeTmp = EVENT_TYPE_LBQ;
              } else {
                eventTypeTmp = EVENT_TYPE_LBQ;
              }

              this.LeaderBoardItem_EventIDList.push({
                id: response.events[idx].id,
                eventID: response.events[idx].event_id,
                eventType: eventTypeTmp, //'LBQ',
                eventName: response.events[idx].event_name,
                startDate: response.events[idx].date_begin._seconds,
                endDate: response.events[idx].date_end._seconds,
              });
            }

            if (this.LeaderBoardItem_EventIDList.length > 0) {
              this.LeaderBoardItem_EventID_AutoFill =
                this.LeaderBoardItem_EventIDList[0].eventID;
              this.LeaderBoardItem_EventID =
                this.LeaderBoardItem_EventIDList[0].eventID;
              this.LeaderBoardItem_EventName =
                this.LeaderBoardItem_EventIDList[0].eventName;

              // event load from firebase have type 'LBQ'
              //this.LeaderBoardItem_EventType = EVENT_TYPE_LBQ;
              this.LeaderBoardItem_EventType =
                this.LeaderBoardItem_EventIDList[0].eventType;

              let _startDate = new Date(
                this.LeaderBoardItem_EventIDList[0].startDate * 1000
              );
              let _endDate = new Date(
                this.LeaderBoardItem_EventIDList[0].endDate * 1000
              );

              this.LeaderBoardItem_EventDate_Begin = _startDate;
              this.LeaderBoardItem_EventDate_End = _endDate;

              this.leaderBoardItem.EventInfo = {
                eventID: this.LeaderBoardItem_EventIDList[0].eventID,
                eventName: this.LeaderBoardItem_EventIDList[0].eventName,
                eventType: this.LeaderBoardItem_EventType,
                startDate: this.LeaderBoardItem_EventIDList[0].startDate,
                endDate: this.LeaderBoardItem_EventIDList[0].endDate,
              };

              this.setDefaultGroupsByEventType();
            }
          }

          //Message({message: "Get getEventDataFromFirebase -> LeaderBoardItem_EventIDList " + JSON.stringify(this.LeaderBoardItem_EventIDList), type: "success", duration: 5 * 1000});
        })
        .catch((error) => {
          Message({
            message: "Can't fetch config data list: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    DoGetCheatTime() {
      getCheatTime()
        .then((response) => {
          //Message({message: "Get getCheatTime: " + JSON.stringify(response.data), type: "success", duration: 5 * 1000});

          let cheatTimeObj = response.data;
          if (cheatTimeObj !== undefined) {
            //{"code":0,"message":"","reply":80}
            if (cheatTimeObj.code == 0) {
              let cheatTimeOffset = cheatTimeObj.reply;

              this.cheatTimeData.Days = Math.floor(cheatTimeOffset / 86400);
              cheatTimeOffset -= this.cheatTimeData.Days * 86400;
              if (cheatTimeOffset < 0) {
                cheatTimeOffset = 0;
              }

              this.cheatTimeData.Hours = Math.floor(cheatTimeOffset / 3600);
              cheatTimeOffset -= this.cheatTimeData.Hours * 3600;
              if (cheatTimeOffset < 0) {
                cheatTimeOffset = 0;
              }

              this.cheatTimeData.Mins = Math.floor(cheatTimeOffset / 60);
              //cheatTimeOffset -= this.cheatTimeData.Mins*60;

              this.cheatTimeData.cheatTimeOffset = cheatTimeOffset;

              // update refresh timer
              if (cheatTimeRefreshTimer) {
                clearInterval(cheatTimeRefreshTimer);
              }

              cheatTimeRefreshTimer = setInterval(
                () => this.OnRefreshCheatCurrentTime(1),
                1000
              );
            }
          }
        })
        .catch((error) => {
          Message({
            message: "getCheatTime failed: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    onViewResultTime() {
      let currentTime = new Date();

      this.cheatTimeData.Current = currentTime.toString();

      let addDayInSecs = this.cheatTimeData.Days * 86400;
      let addHourInSecs = this.cheatTimeData.Hours * 3600;
      let addMinInSecs = this.cheatTimeData.Mins * 60;

      let addTotalInSecs = addDayInSecs + addHourInSecs + addMinInSecs;

      let followingDay = new Date(
        currentTime.getTime() + addTotalInSecs * 1000
      );

      this.cheatTimeData.ResultTime = followingDay.toString();
    },
    CallbackChangeGroupData(data) {
      /*
      Message({
                  message: "CallbackChangeGroupData: " + JSON.stringify(data),
                  type: "success",
                  duration: 5 * 1000
                });*/
    },
    CallbackChangeRewardData(data) {
      //Message({
      //            message: "CallbackChangeRewardData: " + JSON.stringify(data),
      //            type: "success",
      //            duration: 5 * 1000
      //          });
    },
    RefreshApplicationInfos(callback) {
      getApplicationInfos()
        .then((response) => {
          //Message({message: "getApplicationInfos: " + JSON.stringify(response.data), type: "success", duration: 5 * 1000 });

          if (response.data.code === 0) {
            let listApps = response.data.appInfos;

            this.listAppInfos = [];
            for (let idx = 0; idx < listApps.length; idx++) {
              this.listAppInfos.push({
                value: listApps[idx].appID,
                label: listApps[idx].appID,
              });
            }

            if (this.listAppInfos.length > 0) {
              this.SelectedApplicationID = this.listAppInfos[0].value;
            }
          } else {
            Message({
              message: "getApplicationInfos failed code: " + response.data.code,
              type: "error",
              duration: 5 * 1000,
            });
          }

          callback(0);
        })
        .catch((error) => {
          Message({
            message: "getApplicationInfos failed: " + error,
            type: "error",
            duration: 5 * 1000,
          });

          callback(1);
        });
    },
    OnGetLeaderBoardRuntimeStatusList() {
      HelperGetEventDataList()
        .then((response) => {
          //Message({message: "Get Event Data List: " + JSON.stringify(response.data), type: "success", duration: 5 * 1000});

          // eventDataItem:
          //
          //  Status: "IDLE",
          //   isLeaderBoardLocked: false,
          //   LastReset: 0,
          //   NextReset: 0,
          //   IntervalBeforeReset: 300

          this.LeaderBoardServiceList = [];
          this.LeaderBoardServiceList_LBQ = [];
          this.LeaderBoardServiceList_EndLess = [];

          if (LeaderBoardServiceList_Timer) {
            clearInterval(LeaderBoardServiceList_Timer);
          }

          if (
            response.data.code == 0 &&
            response.data &&
            response.data.eventDataList
          ) {
            for (let i = 0; i < response.data.eventDataList.length; i++) {
              let nextResetDate = new Date(
                response.data.eventDataList[i].EventStatus.NextReset * 1000
              );
              let currentDate = new Date(
                response.data.eventDataList[i].CurrentTime * 1000
              );

              this.LeaderBoardServiceList.push({
                AppID: response.data.eventDataList[i].AppID,
                CurrentTime: response.data.eventDataList[i].CurrentTime,
                EventStatus: {
                  Status: response.data.eventDataList[i].EventStatus.Status,
                  LastReset:
                    response.data.eventDataList[i].EventStatus.LastReset,
                  NextReset:
                    response.data.eventDataList[i].EventStatus.NextReset,
                  IntervalBeforeReset:
                    response.data.eventDataList[i].EventStatus
                      .IntervalBeforeReset,
                  eventData: undefined,
                },
                DisplayEventStatus: {
                  NextReset: nextResetDate,
                  CurrentTime: currentDate,
                },
              });
            }

            // refresh current time for 10 seconds
            if (
              this.LeaderBoardServiceList &&
              this.LeaderBoardServiceList.length > 0
            ) {
              LeaderBoardServiceList_Timer = setInterval(() => {
                this.onRefreshCurrentTimeLeaderBoardStatusList(10);

                // Refesh leaderboard all information
                //this.onAllLeaderBoardInfo();
              }, 10000);
            }

            //------------------------------------------------------------------------
            // add leadeboard status list
            for (let i = 0; i < this.LeaderBoardServiceList.length; i++) {
              let foundEndLess = false;
              for (
                let j = 0;
                j < this.LeaderBoardSettingsList_EndLess.length;
                j++
              ) {
                if (
                  this.LeaderBoardSettingsList_EndLess[j].data.name ===
                  this.LeaderBoardServiceList[i].AppID
                ) {
                  foundEndLess = true;
                  break;
                }
              }

              if (foundEndLess) {
                this.LeaderBoardServiceList_EndLess.push(
                  this.LeaderBoardServiceList[i]
                );
              } else {
                this.LeaderBoardServiceList_LBQ.push(
                  this.LeaderBoardServiceList[i]
                );
              }
            }
            //------------------------------------------------------------------------

            //Message({message: "Get Event Data List Show: " + JSON.stringify(response.data.eventDataList), type: "success", duration: 5 * 1000});
          }
        })
        .catch((error) => {
          Message({
            message: "Get Event Data 2: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    onRefreshCurrentTimeLeaderBoardStatusList(intervalInSecs) {
      //Message({message: "Refresh LeaderBoard Status List: ", type: "success", duration: 1000});

      if (this.LeaderBoardServiceList) {
        for (let i = 0; i < this.LeaderBoardServiceList.length; i++) {
          this.LeaderBoardServiceList[i].CurrentTime += intervalInSecs;

          let currentDate = new Date(
            this.LeaderBoardServiceList[i].CurrentTime * 1000
          );

          this.LeaderBoardServiceList[i].DisplayEventStatus.CurrentTime =
            currentDate;
        }
      }
    },
    OnRefreshCheatCurrentTime(intervalInSecs) {
      let currentTime = new Date();
      this.cheatTimeData.Current = currentTime.toString();

      //Message({message: "Get getCheatTime: " + this.cheatTimeData.Current, type: "success", duration: 1000});
    },
    onGetEventData() {
      if (!this.SelectedApplicationID) {
        return;
      }

      HelperGetEventData(this.SelectedApplicationID)
        .then((response) => {
          //Message({message: "Get Event Data: " + JSON.stringify(response.data), type: "success", duration: 5 * 1000});

          this.ServiceData.eventData = response.data.eventData;
          this.ServiceData.Status = response.data.eventData.Status;
          //this.ServiceData.LastReset = response.data.eventData.LastReset;
          let nextResetDate = new Date(
            response.data.eventData.NextReset * 1000
          );
          this.ServiceData.NextReset = nextResetDate.toString();

          this.ServiceData.IntervalBeforeReset = Number(
            response.data.eventData.IntervalBeforeReset
          );
        })
        .catch((error) => {
          Message({
            message: "Get Event Data 1: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    onSetEventData() {
      this.ServiceData.eventData.Status = this.ServiceData.Status;
      //this.ServiceData.eventData.LastReset = this.ServiceData.LastReset;

      let dateNextReset = new Date(this.ServiceData.NextReset);
      let timestampBegin = dateNextReset.getTime() / 1000;

      this.ServiceData.eventData.NextReset = timestampBegin;
      this.ServiceData.eventData.IntervalBeforeReset = Number(
        this.ServiceData.IntervalBeforeReset
      );

      Message({
        message:
          "Set Event appID: " +
          this.SelectedApplicationID +
          ", data: " +
          JSON.stringify(this.ServiceData.eventData) +
          ",timestampBegin:" +
          timestampBegin,
        type: "success",
        duration: 5 * 1000,
      });

      HelperSetEventData(this.SelectedApplicationID, this.ServiceData.eventData)
        .then((response) => {
          Message({
            message: "Set Event Data: " + JSON.stringify(response.data),
            type: "success",
            duration: 5 * 1000,
          });

          this.ServiceData.eventData = response.data.eventData;
          this.ServiceData.Status = response.data.eventData.Status;
          //this.ServiceData.LastReset = response.data.eventData.LastReset;
          //this.ServiceData.NextReset = response.data.eventData.NextReset;

          let nextResetDate = new Date(
            response.data.eventData.NextReset * 1000
          );
          this.ServiceData.NextReset = nextResetDate.toString();

          this.ServiceData.IntervalBeforeReset = Number(
            response.data.eventData.IntervalBeforeReset
          );
        })
        .catch((error) => {
          Message({
            message: "Set Event Data: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    onAppIDChange(event) {
      Message({
        message: "Select application ID: " + event,
        type: "success",
        duration: 5 * 1000,
      });

      this.SelectedApplicationID = event;

      this.onGetEventData();

      // Get all group information
      //this.onAllLeaderBoardInfo();
    },
    onGroupnameChange(event) {
      this.SelectedGroupName = event;
    },
    onEventIDChange(event) {
      // Message({
      //   message:
      //     "Select event ID: " +
      //     event +
      //     ", event list length: " +
      //     this.LeaderBoardItem_EventIDList.length,
      //   type: "success",
      //   duration: 5 * 1000,
      // });


      let isExist = false;
      this.LeaderBoardSettingsList.forEach((configLDBSettings) => {
        let eventInfo = configLDBSettings.data.EventInfo;
        if (eventInfo.eventID === event) {
          Message({
            message: `LDB of Event ID: ${eventInfo.eventID} is existed, eventName = ${eventInfo.eventName}`,
            type: "error",
            duration: 5 * 1000,
          });
          isExist = true;
          return;
        }
      });

      if (isExist) {
        return false;
      }
      

      for (let i = 0; i < this.LeaderBoardItem_EventIDList.length; i++) {
        if (this.LeaderBoardItem_EventIDList[i].eventID === event) {
          this.LeaderBoardItem_EventID = event;
          this.LeaderBoardItem_EventName =
            this.LeaderBoardItem_EventIDList[i].eventName;
          this.LeaderBoardItem_EventType =
            this.LeaderBoardItem_EventIDList[i].eventType;

          let _beginDate = new Date(
            this.LeaderBoardItem_EventIDList[i].startDate * 1000
          );
          let _endDate = new Date(
            this.LeaderBoardItem_EventIDList[i].endDate * 1000
          );

          this.LeaderBoardItem_EventDate_Begin = _beginDate;
          this.LeaderBoardItem_EventDate_End = _endDate;

          // set data to current leader board item
          this.leaderBoardItem.EventInfo.eventID = this.LeaderBoardItem_EventID;
          this.leaderBoardItem.EventInfo.eventName =
            this.LeaderBoardItem_EventName;
          this.leaderBoardItem.EventInfo.eventType =
            this.LeaderBoardItem_EventType;
          this.leaderBoardItem.EventInfo.startDate =
            this.LeaderBoardItem_EventIDList[i].startDate;
          this.leaderBoardItem.EventInfo.endDate =
            this.LeaderBoardItem_EventIDList[i].endDate;

          this.setDefaultGroupsByEventType();

          break;
        }
      }
    },
    onEventTypeChange(event) {
      Message({
        message:
          "Select event ID: " +
          event +
          ", event list length: " +
          this.LeaderBoardItem_EventTypeList.length,
        type: "success",
        duration: 5 * 1000,
      });

      this.LeaderBoardItem_EventType = event;
      this.LeaderBoardItem_EventID = "";
      this.LeaderBoardItem_EventName = "";
      this.leaderBoardItem.EventInfo.eventType = this.LeaderBoardItem_EventType;

      this.setDefaultGroupsByEventType();
    },
    GetTestingUser() {
      HelperGetTestingUserList()
        .then((response) => {
          //Message({message: "Get Testing User Data: " + JSON.stringify(response.data), type: "success", duration: 5 * 1000});

          this.TestingUserInfo = "";
          if (response.data.code === 0) {
            let result = response.data.result;
            if (result && result.length > 0) {
              let displayTxt = "";
              for (let i = 0; i < result.length; i++) {
                displayTxt += result[i].id + "\r\n";
              }

              this.TestingUserInfo = displayTxt;
            }
          }
        })
        .catch((error) => {
          Message({
            message: "Get Testing User Data: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    setDefaultGroupsByEventType() {
      //[{eventType: 'LBQ'}, {eventType: 'EndLess'}],
      if (this.LeaderBoardItem_EventType === EVENT_TYPE_ENDLESS) {
        this.LeaderBoardItem_Groups_TypeList = [
          { TypeName: "us_region_lowerequal_3" },
          { TypeName: "us_region_greater_3" },
          { TypeName: "tier3_region_lowerequal_3" },
          { TypeName: "tier3_region_greater_3" },
          { TypeName: "others_region_lowerequal_3" },
          { TypeName: "others_region_greater_3" },
          { TypeName: "cheaters" },
          { TypeName: "testers" },
        ];

        this.LeaderBoardItem_Groups_Type =
          this.LeaderBoardItem_Groups_TypeList[0].TypeName;
      } else {
        this.LeaderBoardItem_Groups_TypeList = [
          { TypeName: "us" },
          { TypeName: "tier3" },
          { TypeName: "others" },
          { TypeName: "cheaters" },
          { TypeName: "testers" },
        ];

        this.LeaderBoardItem_Groups_Type =
          this.LeaderBoardItem_Groups_TypeList[0].TypeName;
      }
    },
    onGroupOSChange(event) {
      Message({
        message:
          "Select Group OS: " +
          event +
          ", event list length: " +
          this.LeaderBoardItem_Groups_OSList.length,
        type: "success",
        duration: 5 * 1000,
      });

      for (let i = 0; i < this.LeaderBoardItem_Groups_OSList.length; i++) {
        if (this.LeaderBoardItem_Groups_OSList[i].osName === event) {
          this.LeaderBoardItem_Groups_OS = event;

          break;
        }
      }
    },
    onGroupTypeChange(event) {
      Message({
        message:
          "Select Group Type: " +
          event +
          ", event list length: " +
          this.LeaderBoardItem_Groups_TypeList.length,
        type: "success",
        duration: 5 * 1000,
      });

      for (let i = 0; i < this.LeaderBoardItem_Groups_TypeList.length; i++) {
        if (this.LeaderBoardItem_Groups_TypeList[i].TypeName === event) {
          this.LeaderBoardItem_Groups_Type = event;

          break;
        }
      }
    },
    OnChangeEventName(event) {
      //Message({message: "Select begin date: " + this.LeaderBoardItem_EventDate_Begin, type: "success", duration: 5 * 1000});

      this.leaderBoardItem.EventInfo.eventName = this.LeaderBoardItem_EventName;
    },
    OnChangeEventID(event) {
      //Message({message: "Select begin date: " + this.LeaderBoardItem_EventDate_Begin, type: "success", duration: 5 * 1000});

      this.leaderBoardItem.EventInfo.eventID = this.LeaderBoardItem_EventID;
    },
    OnChangeGroupBeginDate(event) {
      Message({
        message: "Select begin date: " + this.LeaderBoardItem_EventDate_Begin,
        type: "success",
        duration: 5 * 1000,
      });

      let date = new Date(this.LeaderBoardItem_EventDate_Begin);
      let timestamp = date.getTime() / 1000;

      this.leaderBoardItem.EventInfo.startDate = timestamp;
    },
    OnChangeGroupEndDate(event) {
      Message({
        message: "Select end date: " + this.LeaderBoardItem_EventDate_End,
        type: "success",
        duration: 5 * 1000,
      });

      let date = new Date(this.LeaderBoardItem_EventDate_End);
      let timestamp = date.getTime() / 1000;

      this.leaderBoardItem.EventInfo.endDate = timestamp;
    },
    onGetDownloadLinks() {
      //Message({message: "Get download links", type: "success", duration: 5 * 1000});
      this.loading = true;

      HelperGetDownloadLinks(this.SelectedApplicationID)
        .then((response) => {
          this.loading = false;

          if (response.data.code === 0) {
            this.downloadLogLinks = [];

            this.downloadLogLinks.push({
              value: response.data.backupLink,
              label: response.data.backupLink,
            });

            this.downloadLogLinks.push({
              value: response.data.logLink,
              label: response.data.logLink,
            });
          } else {
            Message({
              message:
                "Get download links failed: " + JSON.stringify(response.data),
              type: "error",
              duration: 5 * 1000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;

          Message({
            message: "Get download links failed: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    onRestoreDataFromBackup() {
      MessageBox.confirm(
        "Do you confirm Restore LeaderBoard from Backup Data?",
        "Confirm Restore",
        {
          confirmButtonText: "Restore",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;

        HelperRestoreDataFromBackup(this.SelectedApplicationID, this.BackupKey)
          .then((response) => {
            this.loading = false;

            Message({
              message:
                "Restore Data From Backup: " + JSON.stringify(response.data),
              type: "success",
              duration: 5 * 1000,
            });
          })
          .catch((error) => {
            //loadingInstance.close();

            this.loading = false;

            Message({
              message: "Restore Data From Backup failed: " + error,
              type: "error",
              duration: 5 * 1000,
            });
          });
      });
    },
    onApplyLeaderBoardSettings() {
      //Message({message: "onApplyLeaderBoardSettings: ", type: "success", duration: 5 * 1000});

      this.loading = true;

      HelperApplyLeaderBoardAllSettingsPolling()
        .then((response) => {
          this.loading = false;

          if (response.data.code === 0) {
            if (response.data.message === "SUCCESS") {
              Message({
                message: "Apply LeaderBoard Settings: SUCCESS",
                type: "success",
                duration: 5 * 1000,
              });
            } else {
              Message({
                message: "Apply LeaderBoard Settings: " + response.data.message,
                type: "success",
                duration: 5 * 1000,
              });

              if (response.data.message === APPLY_STATUS_PROCESSING) {
                this.LeaderBoard_Apply_Settings_Status =
                  APPLY_STATUS_PROCESSING;
                this.LeaderBoard_Processing_Time_Inc = 0;
                this.loading = true;

                // start a timer to checking status processing next status ...
                //
                setTimeout(() => this.onRecheckApplySettingsStatus(), 5000);
              } else {
                this.loading = false;
              }
            }
          } else {
            if (response.data.code === 10) {
              Message({
                message:
                  response.data.message,
                type: "error",
                duration: 5 * 1000,
              });
            } else {
              Message({
                message: "Apply LeaderBoard Settings: FAILED",
                type: "error",
                duration: 5 * 1000,
              });
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          Message({
            message: "Apply LeaderBoard All Settings: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    onRecheckApplySettingsStatus() {
      HelperGetStatusPolling()
        .then((response) => {
          //Message({message: "Recheck Status Polling ..." + JSON.stringify(response.data), type: "success", duration: 5 * 1000});

          let code = response.data.code;
          let message = response.data.message;

          if (
            message === APPLY_STATUS_PROCESSING ||
            message === APPLY_STATUS_DONE ||
            message === APPLY_STATUS_ENABLE ||
            message === APPLY_STATUS_READY
          ) {
            if (message === APPLY_STATUS_READY) {
              message = APPLY_STATUS_DONE;
            }

            if (message === APPLY_STATUS_DONE) {
              Message({
                message: "Apply Settings ..." + message,
                type: "success",
                duration: 5 * 1000,
              });

              // Get Event Data List
              setTimeout(() => {
                // get all leader board settings
                this.onGetLeaderBoardAllSetings();

                this.OnGetLeaderBoardRuntimeStatusList();
              }, 5000);
            }

            this.LeaderBoard_Apply_Settings_Status = message;

            // start a timer to checking status processing next status ...
            //
            if (message === APPLY_STATUS_PROCESSING) {
              this.loading = true;

              // If message is processing auto recheck status continuely
              setTimeout(() => this.onRecheckApplySettingsStatus(), 5000);
              this.LeaderBoard_Processing_Time_Inc += 5;
            } else {
              this.loading = false;
            }

            //Message({message: "Recheck Status Polling ... " + this.LeaderBoard_Apply_Settings_Status, type: "success", duration: 5 * 1000});
          } //other status
          else {
            this.LeaderBoard_Apply_Settings_Status = APPLY_STATUS_READY;
            this.loading = false;
            Message({
              message: "Recheck Status ... Unknown status " + message,
              type: "error",
              duration: 30 * 1000,
            });
          }
        })
        .catch((error) => {
          this.LeaderBoard_Apply_Settings_Status = APPLY_STATUS_READY;
          this.loading = false;

          Message({
            message: "Apply Settings Failed: " + error,
            type: "error",
            duration: 60 * 1000,
          });
        });
    },
    onSaveResultTime() {
      this.loading = true;

      //let profileID = this.cheatTimeData.cheatTimeOffset;
      let addDayInSecs = this.cheatTimeData.Days * 86400;
      let addHourInSecs = this.cheatTimeData.Hours * 3600;
      let addMinInSecs = this.cheatTimeData.Mins * 60;

      let addTotalInSecs = addDayInSecs + addHourInSecs + addMinInSecs;

      setCheatTime(addTotalInSecs)
        .then((response) => {
          this.loading = false;
          /*
          Message({
                  message: "setCheatTime: " + JSON.stringify(response.data),
                  type: "success",
                  duration: 5 * 1000
                });
*/

          let cheatTimeObj = response.data;
          if (cheatTimeObj !== undefined) {
            //{"code":0,"message":"","reply":80}
            if (cheatTimeObj.code == 0) {
              Message({
                message: "Set Cheat Time: SUCCESS",
                type: "success",
                duration: 5 * 1000,
              });
            } else {
              Message({
                message: "Set Cheat Time: FAILED",
                type: "error",
                duration: 5 * 1000,
              });
            }
          }
        })
        .catch((error) => {
          //loadingInstance.close();

          this.loading = false;

          Message({
            message: "setCheatTime failed: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    onGetUserData() {
      if (this.UserInfo.UserID === "") {
        Message({
          message: "the User ID is empty.",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      HelperGetUserData(this.SelectedApplicationID, this.UserInfo.UserID)
        .then((response) => {
          if (
            response.data &&
            response.data.code == 0 &&
            response.data.userInfo
          ) {
            if (response.data.userScore) {
              let userScore = response.data.userScore;

              this.UserScore.score = response.data.userScore.score;
              this.UserScore.rank = response.data.userScore.rank;
            }

            // update group name
            // group_name = <appID><group_name>
            //
            //if (this.SelectedApplicationID.length < response.data.userInfo.group_name.length)
            if (
              response.data.userInfo.group_name.startsWith(
                this.SelectedApplicationID.toLowerCase()
              )
            ) {
              this.UserInfo.GroupName =
                response.data.userInfo.group_name.substring(
                  this.SelectedApplicationID.toLowerCase().length
                );

              this.UserInfo.Info = JSON.stringify(response.data.userInfo);

              Message({
                message:
                  "Get User Data for User " +
                  this.UserInfo.UserID +
                  " SUCCESS ",
                type: "success",
                duration: 5 * 1000,
              });
            } else {
              Message({
                message:
                  "Get User Data: Invalid group name " +
                  JSON.stringify(response.data),
                type: "error",
                duration: 5 * 1000,
              });

              this.UserInfo.GroupName = "";
            }
          } else {
            Message({
              message: "Get User Data: " + JSON.stringify(response.data),
              type: "error",
              duration: 5 * 1000,
            });

            this.UserInfo.GroupName = "";
          }
        })
        .catch((error) => {
          Message({
            message: "Get User Data: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });

      // Load all group name of current leaderboard
      this.onAllLeaderBoardInfo();
    },
    onChangeUserGroup() {
      if (this.UserInfo.UserID === "") {
        Message({
          message: "the User ID is empty.",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      if (this.UserInfo.GroupName === "") {
        Message({
          message: "the Group Name is empty.",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      let group_name = this.SelectedApplicationID + this.SelectedGroupName;

      HelperChangeUserGroup(
        this.SelectedApplicationID,
        this.UserInfo.UserID,
        group_name
      )
        .then((response) => {
          if (response.data && response.data.code == 0) {
            // update group name
            //this.UserInfo.GroupName = response.data.userInfo.group_name;
            Message({
              message:
                "Move user " +
                this.UserInfo.UserID +
                " to group " +
                this.SelectedGroupName +
                " SUCCESS ",
              type: "success",
              duration: 5 * 1000,
            });

            this.onGetUserData();
          } else {
            Message({
              message: "Move User to Group: " + JSON.stringify(response.data),
              type: "error",
              duration: 5 * 1000,
            });
          }
        })
        .catch((error) => {
          Message({
            message: "Move User to Group FAILED: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    onRestoreCheatUserGroupToNomalGroup() {
      if (this.UserInfo.UserID === "") {
        Message({
          message: "the User ID is empty.",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      HelperRestoreCheatUserGroupToNomalGroup(
        this.SelectedApplicationID,
        this.UserInfo.UserID
      )
        .then((response) => {
          if (response.data && response.data.code == 0) {
            // update group name
            Message({
              message:
                "Restore user " +
                this.UserInfo.UserID +
                " from cheating group SUCCESS",
              type: "success",
              duration: 5 * 1000,
            });

            this.onGetUserData();
          } else {
            Message({
              message:
                "Restore user : " +
                this.UserInfo.UserID +
                " FAILED " +
                JSON.stringify(response.data),
              type: "error",
              duration: 5 * 1000,
            });
          }
        })
        .catch((error) => {
          Message({
            message: "Move User to Group FAILED: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    onSetBlackListUser() {
      // set user black list
      let opt = 1;

      if (this.BlackListUser.userID === "") {
        Message({
          message: "the User ID is empty.",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      if (this.SelectedApplicationID === "") {
        Message({
          message: "the Application ID is empty.",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      setBlackListUser(
        this.SelectedApplicationID,
        this.BlackListUser.userID,
        this.BlackListUser.description,
        opt
      )
        .then((response) => {
          Message({
            message: "set BlackList User SUCCEED ", // + JSON.stringify(response.data),
            type: "success",
            duration: 5 * 1000,
          });

          this.RefreshBlackList();
        })
        .catch((error) => {
          Message({
            message: "set BlackList User failed: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    onUnsetBlackListUser() {
      // unset user black list
      let opt = 2;

      if (this.BlackListUser.userID === "") {
        Message({
          message: "the User ID is empty.",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      if (this.SelectedApplicationID === "") {
        Message({
          message: "the Application ID is empty.",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      setBlackListUser(
        this.SelectedApplicationID,
        this.BlackListUser.userID,
        this.BlackListUser.description,
        opt
      )
        .then((response) => {
          Message({
            message: "Unset BlackList User SUCCEED", // + JSON.stringify(response.data),
            type: "success",
            duration: 5 * 1000,
          });

          this.RefreshBlackList();
        })
        .catch((error) => {
          Message({
            message: "Unset BlackList User failed: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    onRefreshBlackListUser() {
      // refresh black list
      this.RefreshBlackList();
    },
    RefreshBlackList() {
      if (this.SelectedApplicationID === "") {
        //Message({
        //      message: "the Application ID is empty.",
        //      type: "error",
        //      duration: 5 * 1000
        //    });

        return;
      }

      getBlackList(this.SelectedApplicationID)
        .then((response) => {
          if (response.data.code === 0) {
            this.BlackList = response.data.reply;

            this.BlackListDisplayList = [];

            //{"id": "user_1","description": "SRC_BATTLE"}...
            for (let idx = 0; idx < this.BlackList.length; idx++) {
              this.BlackListDisplayList.push({
                idx: idx,
                userID: this.BlackList[idx].id,
                description: this.BlackList[idx].description,
              });
            }
          } else {
            this.BlackListDisplayList = [];
          }
        })
        .catch((error) => {
          Message({
            message: "getBlackList failed: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    //---------------------------------------------------------

    handleClick(obj, e) {
      if (obj.label === "LeaderBoard Black List") {
        // refresh black list
        this.RefreshBlackList();
      } else if (
        obj.label === "LeaderBoard Settings" ||
        obj.label === "LeaderBoard LBQ" ||
        obj.label === "LeaderBoard EndLess"
      ) {
        // get all leader board settings
        this.onGetLeaderBoardAllSetings();

        // Get Event Data List
        this.OnGetLeaderBoardRuntimeStatusList();
      } else if (obj.label === "LeaderBoard Add") {
        this.getEventDataList();
      }
    },
    cancelEdit(row) {
      row.updateMode = false;
      row.editMode = false;
    },
    onDelete(row) {
      MessageBox.confirm(
        "Do you confirm delete item: " + row.id + "?",
        "Confirm delete",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        let uploadSettings = [];

        for (let idx = 0; idx < this.LeaderBoardSettingsList.length; idx++) {
          let timestampStartDate =
            this.LeaderBoardSettingsList[
              idx
            ].dataDisplay.EventInfo.startDate.getTime() / 1000;
          let timestampEndtDate =
            this.LeaderBoardSettingsList[
              idx
            ].dataDisplay.EventInfo.endDate.getTime() / 1000;

          this.LeaderBoardSettingsList[idx].data.EventInfo.startDate =
            timestampStartDate;
          this.LeaderBoardSettingsList[idx].data.EventInfo.endDate =
            timestampEndtDate;

          if (this.LeaderBoardSettingsList[idx].id !== row.id) {
            uploadSettings.push(this.LeaderBoardSettingsList[idx].data);
          }
        }

        //Message({message: "Delete LeaderBoard All Settings Data: " + JSON.stringify(uploadSettings), type: "success", duration: 5 * 1000});

        HelperSetLeaderBoardAllSettings(uploadSettings)
          .then((response) => {
            //Message({message: "Set LeaderBoard All Settings Data: " + JSON.stringify(response.data), type: "success", duration: 5 * 1000});

            // get all leader board settings
            this.onGetLeaderBoardAllSetings();
          })
          .catch((error) => {
            Message({
              message: "Set LeaderBoard All Settings 1: " + error,
              type: "error",
              duration: 5 * 1000,
            });
          });
      });
    },
    confirmEdit(row) {
      MessageBox.confirm(
        "Do you confirm edit item: " + row.id + "?",
        "Confirm edit",
        {
          confirmButtonText: "Edit",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        let uploadSettings = [];

        //convert startDate, endDate
        for (let idx = 0; idx < this.LeaderBoardSettingsList.length; idx++) {
          let timestampStartDate =
            this.LeaderBoardSettingsList[
              idx
            ].dataDisplay.EventInfo.startDate.getTime() / 1000;
          let timestampEndtDate =
            this.LeaderBoardSettingsList[
              idx
            ].dataDisplay.EventInfo.endDate.getTime() / 1000;

          this.LeaderBoardSettingsList[idx].data.EventInfo.startDate =
            timestampStartDate;
          this.LeaderBoardSettingsList[idx].data.EventInfo.endDate =
            timestampEndtDate;

          uploadSettings.push(this.LeaderBoardSettingsList[idx].data);
        }

        HelperSetLeaderBoardAllSettings(uploadSettings)
          .then((response) => {
            // get all leader board settings
            this.onGetLeaderBoardAllSetings();
          })
          .catch((error) => {
            Message({
              message: "Set LeaderBoard All Settings 2: " + error,
              type: "error",
              duration: 5 * 1000,
            });
          });
      });
    },
    onExport(row) {
      MessageBox.confirm(
        "Do you confirm export leaderboard leaderboard: " + row.data.name + "?",
        "Confirm export",
        {
          confirmButtonText: "Export",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        let uploadSettings = [];
        let found = false;
        let name = "";

        for (let idx = 0; idx < this.LeaderBoardSettingsList.length; idx++) {
          let timestampStartDate =
            this.LeaderBoardSettingsList[
              idx
            ].dataDisplay.EventInfo.startDate.getTime() / 1000;
          let timestampEndtDate =
            this.LeaderBoardSettingsList[
              idx
            ].dataDisplay.EventInfo.endDate.getTime() / 1000;

          this.LeaderBoardSettingsList[idx].data.EventInfo.startDate =
            timestampStartDate;
          this.LeaderBoardSettingsList[idx].data.EventInfo.endDate =
            timestampEndtDate;

          if (this.LeaderBoardSettingsList[idx].id === row.id) {
            uploadSettings.push(this.LeaderBoardSettingsList[idx].data);
            name = this.LeaderBoardSettingsList[idx].data.name;

            found = true;
            break;
          }
        }

        if (found) {
          //Message({message: "Export LeaderBoard Settings Data: " + JSON.stringify(uploadSettings), type: "success", duration: 5 * 1000});

          let export_filename = "export_leaderboard_" + name + ".json";
          DownloadJS(
            JSON.stringify(uploadSettings),
            export_filename,
            "text/plain"
          );
        } else {
          Message({
            message: "Export LeaderBoard Settings Data Failed",
            type: "error",
            duration: 5 * 1000,
          });
        }
      });
    },
    OnLeaderBoardItem_Groups_Add() {
      // full: <leaderboard name>_<os>_<type>_
      // display: _<os>_<type>_
      //
      this.LeaderBoardItem_Groups_Name =
        "_" +
        this.LeaderBoardItem_Groups_OS +
        "_" +
        this.LeaderBoardItem_Groups_Type +
        "_";

      if (!this.LeaderBoardItem_Groups_Name) {
        Message({
          message: "LeaderBoard Group Name is empty!",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      if (!this.IsStringAlphaNumber(this.LeaderBoardItem_Groups_Name)) {
        Message({
          message:
            "LeaderBoard Group Name is only valid for characters alpha, number and '_'",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      let isExist = false;

      for (let idx = 0; idx < this.leaderBoardItem.Groups.length; idx++) {
        if (
          this.leaderBoardItem.Groups[idx].name ===
          this.LeaderBoardItem_Groups_Name
        ) {
          isExist = true;
          break;
        }
      }

      if (isExist) {
        Message({
          message: "LeaderBoard Group Name is already existing!",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      MessageBox.confirm(
        "Do you confirm Add group:" +
          this.LeaderBoardItem_Groups_Name +
          ", count:" +
          this.LeaderBoardItem_Groups_Count +
          ", size:" +
          this.LeaderBoardItem_Groups_Size +
          ", payment size:" +
          this.LeaderBoardItem_Groups_Payment_Size +
          ", none payment size: " +
          this.LeaderBoardItem_Groups_None_Payment_Limit_Size_1,
        "Confirm edit",
        {
          confirmButtonText: "Add",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.leaderBoardItem.Groups.push({
          name: this.LeaderBoardItem_Groups_Name,
          count: this.LeaderBoardItem_Groups_Count,
          size: this.LeaderBoardItem_Groups_Size,
          payment_size: this.LeaderBoardItem_Groups_Payment_Size,
          none_payment_limit_size_1:
            this.LeaderBoardItem_Groups_None_Payment_Limit_Size_1,
        });
      });
    },
    OnLeaderBoardItem_Groups_Delete() {
      // full: <leaderboard name>_<os>_<type>_
      // display: _<os>_<type>_
      //
      this.LeaderBoardItem_Groups_Name =
        "_" +
        this.LeaderBoardItem_Groups_OS +
        "_" +
        this.LeaderBoardItem_Groups_Type +
        "_";

      MessageBox.confirm(
        "Do you confirm Delete group:" + this.LeaderBoardItem_Groups_Name,
        "Confirm edit",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        for (let idx = 0; idx < this.leaderBoardItem.Groups.length; idx++) {
          if (
            this.leaderBoardItem.Groups[idx].name ===
            this.LeaderBoardItem_Groups_Name
          ) {
            this.leaderBoardItem.Groups.splice(idx, 1);
            break;
          }
        }
      });
    },
    OnLeaderBoardItem_Rewards_Add() {
      if (!this.LeaderBoardItem_Rewards_Gifts) {
        Message({
          message: "Gift list is empty!",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      MessageBox.confirm(
        "Do you confirm Add reward id:" +
          this.LeaderBoardItem_Rewards_ID +
          ", title:" +
          this.LeaderBoardItem_Rewards_Title +
          ", rank min:" +
          this.LeaderBoardItem_Rewards_RankMin +
          ", rank max:" +
          this.LeaderBoardItem_Rewards_RankMax +
          ", required point: " +
          this.LeaderBoardItem_Rewards_RequirePoint +
          ", gifts: " +
          this.LeaderBoardItem_Rewards_Gifts,
        "Confirm edit",
        {
          confirmButtonText: "Add",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.leaderBoardItem.Rewards.push({
          id: Number(this.LeaderBoardItem_Rewards_ID),
          //eventId: this.LeaderBoardItem_EventID,
          title: this.LeaderBoardItem_Rewards_Title,
          rankMin: this.LeaderBoardItem_Rewards_RankMin,
          rankMax: this.LeaderBoardItem_Rewards_RankMax,
          requiredPoint: this.LeaderBoardItem_Rewards_RequirePoint,
          gifts: this.LeaderBoardItem_Rewards_Gifts,
        });

        let maxID = 0;
        for (let idx = 0; idx < this.leaderBoardItem.Rewards.length; idx++) {
          if (maxID < this.leaderBoardItem.Rewards[idx].id) {
            maxID = this.leaderBoardItem.Rewards[idx].id;
          }
        }

        this.LeaderBoardItem_Rewards_ID = maxID + 1;

        //Message({message: "OnLeaderBoardItem_Rewards_Delete: " + JSON.stringify(this.leaderBoardItem.Rewards) + ", LeaderBoardItem_Rewards_ID : " + this.LeaderBoardItem_Rewards_ID, type: "success", duration: 5 * 1000});
      });
    },
    OnLeaderBoardItem_Rewards_Delete() {
      MessageBox.confirm(
        "Do you confirm Delete reward:" + this.LeaderBoardItem_Rewards_ID,
        "Confirm edit",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        //Message({message: "OnLeaderBoardItem_Rewards_Delete: " + JSON.stringify(this.leaderBoardItem.Rewards) + ", LeaderBoardItem_Rewards_ID : " + this.LeaderBoardItem_Rewards_ID, type: "success", duration: 5 * 1000});

        for (let idx = 0; idx < this.leaderBoardItem.Rewards.length; idx++) {
          if (
            this.leaderBoardItem.Rewards[idx].id ===
            Number(this.LeaderBoardItem_Rewards_ID)
          ) {
            this.leaderBoardItem.Rewards.splice(idx, 1);

            //Message({message: "OnLeaderBoardItem_Rewards_Delete: " + this.LeaderBoardItem_Rewards_ID, type: "success", duration: 5 * 1000});
            break;
          }
        }
      });
    },
    OnLeaderBoardItem_Rewards_Change(data) {
      //Message({message: "OnLeaderBoardItem_Rewards_Change: " + JSON.stringify(data.rewards), type: "success", duration: 5 * 1000});
      this.LeaderBoardItem_Rewards_Gifts = JSON.stringify(data.rewards);
    },
    OnLeaderBoardItem_Add() {
      this.DoLeaderBoardItem_Add(this.leaderBoardItem);
    },
    DoLeaderBoardItem_Add(in_leaderBoardItem) {
      this.leaderBoardItem = in_leaderBoardItem;


      let isExistEventID = false;
      this.LeaderBoardSettingsList.forEach((configLDBSettings) => {
        let eventInfo = configLDBSettings.data.EventInfo;
        if (eventInfo.eventID === this.leaderBoardItem.EventInfo.eventID) {
          Message({
            message: `LDB of Event ID: ${eventInfo.eventID} is existed, eventName = ${eventInfo.eventName}`,
            type: "error",
            duration: 5 * 1000,
          });
          isExistEventID = true;
          return;
        }
      });

      if (isExistEventID) {
        return false;
      }

      if (!this.leaderBoardItem.name) {
        Message({
          message: "LeaderBoard Name is empty!",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      // The leaderboard 'raiboss...' is created only at GameService/RaidBoss on admin tool
      if (this.leaderBoardItem.name.indexOf("raidboss") === 0) {
        Message({
          message: 'LeaderBoard Name must not contain prefix "raidboss" !',
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      if (this.leaderBoardItem.name.indexOf("littleboss") === 0) {
        Message({
          message: 'LeaderBoard Name must not contain "littleboss" !',
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      if (this.leaderBoardItem.name.indexOf("grandboss") === 0) {
        Message({
          message: 'LeaderBoard Name must not contain "grandboss" !',
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      if (!this.IsStringAlphaNumber(this.leaderBoardItem.name)) {
        Message({
          message:
            "LeaderBoard Name is only valid for characters alpha, number and '_'",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      this.leaderBoardItem.name = this.leaderBoardItem.name.toLowerCase();

      // generate a new leaderboard
      //
      let isExist = false;
      let id = 0;
      for (let idx = 0; idx < this.LeaderBoardSettingsList.length; idx++) {
        if (id < this.LeaderBoardSettingsList[idx].id) {
          id = this.LeaderBoardSettingsList[idx].id;
        }

        if (
          this.leaderBoardItem.name ===
          this.LeaderBoardSettingsList[idx].data.name
        ) {
          isExist = true;
        }
      } //for

      if (isExist) {
        Message({
          message: "LeaderBoard Name is already existing!",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      if (
        !this.leaderBoardItem.Groups ||
        this.leaderBoardItem.Groups.length <= 0
      ) {
        Message({
          message: "LeaderBoard Group List is empty!",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      if (
        !this.leaderBoardItem.Rewards ||
        this.leaderBoardItem.Rewards.length <= 0
      ) {
        Message({
          message: "LeaderBoard Reward List is empty!",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      id++;

      let name = this.leaderBoardItem.name;

      //Event Information
      this.leaderBoardItem.EventInfo.endDate +=
        this.LeaderBoardItem_EventDate_Lock_Before_End;

      let EventInfo = this.leaderBoardItem.EventInfo;

      let startDate = new Date(EventInfo.startDate * 1000);
      let endDate = new Date(EventInfo.endDate * 1000);

      let eventInfoDisplay = {
        eventID: EventInfo.eventID,
        eventType: EventInfo.eventType,
        eventName: EventInfo.eventName,
        startDate: startDate,
        endDate: endDate,
      };

      // Groups

      // Checking all group type must exist...
      let found = false;
      for (let i = 0; i < this.LeaderBoardItem_Groups_TypeList.length; i++) {
        found = false;
        for (let j = 0; j < this.leaderBoardItem.Groups.length; j++) {
          if (
            this.leaderBoardItem.Groups[j].name.includes(
              this.LeaderBoardItem_Groups_TypeList[i].TypeName
            )
          ) {
            found = true;
            break;
          }
        }

        if (!found) {
          Message({
            message:
              'The Group Type "' +
              this.LeaderBoardItem_Groups_TypeList[i].TypeName +
              '" must be added!',
            type: "error",
            duration: 5 * 1000,
          });
          return;
        }
      }

      for (let idx = 0; idx < this.leaderBoardItem.Groups.length; idx++) {
        if (this.leaderBoardItem.Groups[idx].name.startsWith(name)) {
          break;
        }

        this.leaderBoardItem.Groups[idx].name =
          name.toLowerCase() + this.leaderBoardItem.Groups[idx].name;
      }

      let Groups = this.leaderBoardItem.Groups;

      // Rewards
      //for(let idx = 0; idx < this.leaderBoardItem.Rewards.length; idx++)
      //{
      //this.leaderBoardItem.Rewards[idx].eventId = this.leaderBoardItem.EventInfo.eventID;
      //}
      let Rewards = this.leaderBoardItem.Rewards;

      this.LeaderBoardSettingsList.push({
        id: id,
        data: {
          name: name,
          Groups: Groups,
          Rewards: Rewards,
          EventInfo: EventInfo,
        },
        dataDisplay: {
          EventInfo: eventInfoDisplay,
        },
        editMode: false,
        updateMode: false,
      });

      Message({
        message: "A new LeaderBoard event added " + JSON.stringify(EventInfo),
        type: "success",
        duration: 5 * 1000,
      });

      let uploadSettings = [];

      for (let idx = 0; idx < this.LeaderBoardSettingsList.length; idx++) {
        uploadSettings.push(this.LeaderBoardSettingsList[idx].data);
      }

      //-------------------------------------------------------------------------
      this.LeaderBoardSettingsList_LBQ = [];
      this.LeaderBoardSettingsList_EndLess = [];

      for (let idx = 0; idx < this.LeaderBoardSettingsList.length; idx++) {
        if (
          this.LeaderBoardSettingsList[idx].data.EventInfo.eventType ===
          EVENT_TYPE_ENDLESS
        ) {
          this.LeaderBoardSettingsList_EndLess.push(
            this.LeaderBoardSettingsList[idx]
          );
        } else {
          this.LeaderBoardSettingsList_LBQ.push(
            this.LeaderBoardSettingsList[idx]
          );
        }
      }
      //-------------------------------------------------------------------------

      HelperSetLeaderBoardAllSettings(uploadSettings)
        .then((response) => {
          this.loading = false;
          Message({
            message:
              "Set LeaderBoard All Settings Data 3: " +
              JSON.stringify(response.data),
            type: "success",
            duration: 5 * 1000,
          });

          this.leaderBoardItem.name = "";
          this.leaderBoardItem.Groups = [];
          //this.leaderBoardItem.Rewards = [];
          //this.leaderBoardItem.EventInfo;

          this.LeaderBoard_Apply_Settings_Status = APPLY_STATUS_ENABLE;
        })
        .catch((error) => {
          //loadingInstance.close();

          this.LeaderBoardReset_Item();

          this.loading = false;
          Message({
            message: "Set LeaderBoard All Settings 4: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    LeaderBoardReset_Item() {
      this.leaderBoardItem.name = "";
      this.leaderBoardItem.Groups = [];

      // set data to current leader board item
      this.leaderBoardItem.EventInfo = {
        eventID: this.LeaderBoardItem_EventID,
        eventType: this.LeaderBoardItem_EventType,
        eventName: this.LeaderBoardItem_EventName,
        startDate: this.LeaderBoardItem_EventDate_Begin,
        endDate: this.LeaderBoardItem_EventDate_End,
      };
    },
    IsStringAlphaNumber(input) {
      var letters = /^[0-9a-zA-Z_]+$/;
      if (input.match(letters)) {
        return true;
      } else {
        return false;
      }
    },
    OnHandleImportSettingsFile(file, fileList) {
      //Message({message: "OnHandleImportSettingsFile content: " + file.raw, type: "error", duration: 5 * 1000});
      try {
        let in_leaderBoardItem;
        let _objThis = this;

        if (file) {
          TryParseUploadFile_ReadTextFile(file.raw, function (code, data) {
            if (code === 0) {
              let settings = JSON.parse(data);

              if (settings !== undefined && settings.length > 0) {
                in_leaderBoardItem = settings[0];

                _objThis.DoLeaderBoardItem_Add(in_leaderBoardItem);
              }
            }
          });
        }
      } catch (err) {
        Message({
          message: "Exception Import File failed: " + err,
          type: "error",
          duration: 5 * 1000,
        });
      }
    },
    OnHandleRewardFileUpload(file, fileList) {
      //Message({message: "OnHandleRewardFileUpload content: " + file.raw, type: "error", duration: 5 * 1000});

      this.leaderBoardItem.Rewards = [];

      let leaderBoardItem_Rewards = this.leaderBoardItem.Rewards;

      if (file) {
        TryParseUploadFile_ReadTextFile(file.raw, function (code, data) {
          if (code === 0) {
            HelperParseRewardFromFile(data)
              .then((response) => {
                if (response.data.code === 0) {
                  Message({
                    message: "Import rewards from file succsessfully",
                    type: "success",
                    duration: 5 * 1000,
                  });

                  for (let idx = 0; idx < response.data.rewards.length; idx++) {
                    let itemGift = JSON.parse(response.data.rewards[idx].gifts);
                    if (!itemGift) {
                      Message({
                        message:
                          "Invalid Gift content: " +
                          response.data.rewards[idx].gifts,
                        type: "error",
                        duration: 5 * 1000,
                      });
                      return;
                    }

                    leaderBoardItem_Rewards.push({
                      id: Number(response.data.rewards[idx].id),
                      //eventId: response.data.rewards[idx].eventId,
                      title: response.data.rewards[idx].title,
                      rankMin: response.data.rewards[idx].rankMin,
                      rankMax: response.data.rewards[idx].rankMax,
                      requiredPoint: response.data.rewards[idx].requiredPoint,
                      gifts: response.data.rewards[idx].gifts,
                    });
                  }
                } else {
                  Message({
                    message: "Set Helper Parse Reward From File ",
                    type: "error",
                    duration: 5 * 1000,
                  });
                }
              })
              .catch((error) => {
                //loadingInstance.close();
                Message({
                  message:
                    "[HelperParseRewardFromFile]Set LeaderBoard All Settings 5: " +
                    error,
                  type: "error",
                  duration: 5 * 1000,
                });
              });
          } else {
            Message({
              message: "Set OnHandleRewardFileUpload code: " + code,
              type: "error",
              duration: 5 * 1000,
            });
          }
        });
      }
    },
    OnHandleImportBlackList(file, fileList) {
      if (file) {
        TryParseUploadFile_ReadTextFile(file.raw, function (code, data) {
          if (code === 0) {
            Message({
              message: "Set OnHandleImportBlackList data: " + data.toString(),
              type: "success",
              duration: 5 * 1000,
            });
          } else {
            Message({
              message: "Set OnHandleImportBlackList code: " + code,
              type: "error",
              duration: 5 * 1000,
            });
          }
        });
      }
    },
    OnHandleImportUser(file, fileList) {
      //Message({message: "OnHandleImportUser... " + file, type: "success", duration: 5 * 1000});
      let _SelectedApplicationID = this.SelectedApplicationID;
      let dataUsers = [];
      let _objThis = this;

      if (file) {
        TryParseUploadFile_ReadTextFile(file.raw, function (code, data) {
          //Message({message: "OnHandleImportUser... " + data.toString(), type: "success", duration: 5 * 1000});

          if (code === 0) {
            let fileContent = data.toString();
            let fileParts = fileContent.split(/\r?\n/);

            for (let idx = 0; idx < fileParts.length; idx++) {
              if (!fileParts[idx]) {
                continue;
              }

              let userData = JSON.parse(fileParts[idx]);

              if (userData.info) {
                let userInfo = JSON.parse(userData.info);

                //{
                //    "id": "user_8",
                //    "name": "name_user_8",
                //    "avatarID": "MICHAEL_BENNET",
                //    "country": "vn",
                //    "state": 1,
                //    "group_name": "zs_android_others_1",
                //    "frameID": "0",
                //    "last_reset_time": 1601551350
                //}

                let payCount = 0;
                if (userInfo.payCount) payCount = userInfo.payCount;

                let maxRegion = 1;
                if (userInfo.maxRegion) maxRegion = userInfo.maxRegion;

                let maxMainMission = 1;
                if (userInfo.maxMainMission)
                  maxMainMission = userInfo.maxMainMission;

                let activeDay = 0;
                if (userInfo.activeDay) activeDay = userInfo.activeDay;

                dataUsers.push({
                  id: userInfo.id,
                  name: userInfo.name,
                  country: userInfo.country,
                  avatarID: userInfo.avatarID,
                  frameID: userInfo.frameID,
                  payCount: payCount,
                  maxRegion: maxRegion,
                  maxMainMission: maxMainMission,
                  activeDay: activeDay,
                });
              }
            }
          } else {
            Message({
              message: "Set OnHandleImportUser code: " + code,
              type: "error",
              duration: 5 * 1000,
            });
          }

          //-------------------------------------------------------------------------------
          if (dataUsers.length > 0) {
            //Message({message: "Send OnHandleImportUser data: " + JSON.stringify(dataUsers), type: "success", duration: 5 * 1000});

            HelperTryAddTestUsers(
              _SelectedApplicationID,
              JSON.stringify(dataUsers)
            )
              .then((response) => {
                if (response.data.code === 0) {
                  Message({
                    message: "Try Add Test Users: SUCCESS ",
                    type: "success",
                    duration: 5 * 1000,
                  });
                } else {
                  Message({
                    message:
                      "Try Add Test Users: FAILED " + response.data.message,
                    type: "error",
                    duration: 5 * 1000,
                  });
                }

                // Refesh leaderboard all information
                setTimeout(() => _objThis.onAllLeaderBoardInfo(), 1000);
              })
              .catch((error) => {
                Message({
                  message: "Try Add Test Users: " + error,
                  type: "error",
                  duration: 5 * 1000,
                });
              });
          }
          //-------------------------------------------------------------------------------
        });
      }
    },
    OnHandleImportUserScore(file, fileList) {
      let _SelectedApplicationID = this.SelectedApplicationID;
      let dataUserScores = [];
      let _objThis = this;

      if (file) {
        TryParseUploadFile_ReadTextFile(file.raw, function (code, data) {
          //Message({message: "OnHandleImportUserScore... " + data.toString(), type: "success", duration: 5 * 1000});

          if (code === 0) {
            let fileContent = data.toString();
            let fileParts = fileContent.split(/\r?\n/);

            for (let idx = 0; idx < fileParts.length; idx++) {
              if (!fileParts[idx]) {
                continue;
              }

              let userDataScore = JSON.parse(fileParts[idx]);

              if (userDataScore) {
                //{
                //"id":"user_4",
                //"score":"1"
                //}

                dataUserScores.push({
                  id: userDataScore.id,
                  score: userDataScore.score,
                });
              }

              //Message({message: "Set OnHandleImportUserScore data: " + JSON.stringify(userDataScore), type: "success", duration: 5 * 1000});
            }
          } else {
            Message({
              message: "Set OnHandleImportUserScore code: " + code,
              type: "error",
              duration: 5 * 1000,
            });
          }

          //-------------------------------------------------------------------------------
          if (dataUserScores.length > 0) {
            //Message({message: "Send OnHandleImportUserScore data: " + JSON.stringify(dataUserScores), type: "success", duration: 5 * 1000});

            HelperTryAddTestUserScores(
              _SelectedApplicationID,
              JSON.stringify(dataUserScores)
            )
              .then((response) => {
                //Message({message: "Try Add Test Users response: " + JSON.stringify(response.data), type: "success", duration: 5 * 1000});

                if (response.data.code === 0) {
                  Message({
                    message: "Try Add Test User Scores: SUCCESS ",
                    type: "success",
                    duration: 5 * 1000,
                  });
                } else {
                  Message({
                    message:
                      "Try Add Test Scores: FAILED " + response.data.message,
                    type: "error",
                    duration: 5 * 1000,
                  });
                }

                // Refesh leaderboard all information
                setTimeout(() => _objThis.onAllLeaderBoardInfo(), 1000);
              })
              .catch((error) => {
                Message({
                  message: "Try Add Test Scores: " + error,
                  type: "error",
                  duration: 5 * 1000,
                });
              });
          }
          //-------------------------------------------------------------------------------
        });
      }
    },
    onAllLeaderBoardInfo() {
      if (
        !this.SelectedApplicationID ||
        this.SelectedApplicationID.length <= 0
      ) {
        return;
      }

      let _LeaderBoardData = this.LeaderBoardData;

      HelperGetAllLeaderBoardInfo(this.SelectedApplicationID)
        .then((response) => {
          //Message({message: "onAllLeaderBoardInfo " + JSON.stringify(response), type: "success", duration: 5 * 1000});

          if (response.data.code === 0) {
            let arrInfo = JSON.parse(response.data.info);
            let displayTxt = "";
            let displayLeaderboardName = "";
            let appNameLen = this.SelectedApplicationID.length;

            displayTxt += this.SelectedApplicationID;
            displayTxt += "\r\n";
            displayTxt += "\r\n";

            _LeaderBoardData.TopNames = [];
            this.listGroupNames = [];

            for (let idx = 0; idx < arrInfo.length; idx++) {
              displayLeaderboardName = arrInfo[idx].name.substring(appNameLen);
              displayTxt += displayLeaderboardName;

              //displayTxt += arrInfo[idx].name;
              displayTxt += ": ";
              displayTxt += arrInfo[idx].count;
              displayTxt += "\r\n";

              _LeaderBoardData.TopNames.push(displayLeaderboardName);

              // add group infos
              this.listGroupNames.push({
                value: displayLeaderboardName,
                label: displayLeaderboardName,
              });
            }

            if (this.listGroupNames.length > 0) {
              this.SelectedGroupName = this.listGroupNames[0].value;
            }

            _LeaderBoardData.Info = displayTxt;
            _LeaderBoardData.AppID = this.SelectedApplicationID;
            _LeaderBoardData.CurrentTopNameIndex = 0;
            _LeaderBoardData.CurrentTopInfo = "";

            _LeaderBoardData.CurrentTopName = "";
            if (
              _LeaderBoardData.TopNames.length > 0 &&
              _LeaderBoardData.TopNames.length >
                _LeaderBoardData.CurrentTopNameIndex
            ) {
              _LeaderBoardData.CurrentTopName =
                _LeaderBoardData.TopNames[_LeaderBoardData.CurrentTopNameIndex];

              this.onLeaderBoardTop();
            }
          } else {
            Message({
              message:
                "Get All LeaderBoard Information failed" +
                JSON.stringify(response),
              type: "error",
              duration: 5 * 1000,
            });
          }

          //Message({message: "Get getEventDataFromFirebase -> LeaderBoardItem_EventIDList " + JSON.stringify(this.LeaderBoardItem_EventIDList), type: "success", duration: 5 * 1000});
        })
        .catch((error) => {
          Message({
            message: "Can't get all leaderboard information: " + error,
            type: "error",
            duration: 5 * 1000,
          });
          this.loading = false;
        });
    },
    onLeaderBoardFindTopNext() {
      this.LeaderBoardData.CurrentTopNameIndex++;
      if (
        this.LeaderBoardData.CurrentTopNameIndex < 0 ||
        this.LeaderBoardData.CurrentTopNameIndex >=
          this.LeaderBoardData.TopNames.length
      ) {
        this.LeaderBoardData.CurrentTopNameIndex = 0;
      }

      this.LeaderBoardData.CurrentTopName = "";
      if (
        this.LeaderBoardData.TopNames.length > 0 &&
        this.LeaderBoardData.TopNames.length >
          this.LeaderBoardData.CurrentTopNameIndex
      ) {
        this.LeaderBoardData.CurrentTopName =
          this.LeaderBoardData.TopNames[
            this.LeaderBoardData.CurrentTopNameIndex
          ];
      }

      this.onLeaderBoardTop();
    },
    onLeaderBoardTop() {
      if (
        !this.SelectedApplicationID ||
        !this.LeaderBoardData.CurrentTopName ||
        this.LeaderBoardData.CurrentTopName.length <= 0
      ) {
        return;
      }

      let fullGroupName =
        this.SelectedApplicationID + this.LeaderBoardData.CurrentTopName;
      let _LeaderBoardData = this.LeaderBoardData;

      HelperGetTopUser(this.SelectedApplicationID, fullGroupName)
        .then((response) => {
          if (response.data.code == 0 && response.data && response.data.top) {
            //Message({message: "Get Top User List: " + JSON.stringify(response.data), type: "success", duration: 5 * 1000});

            //"top": [{
            //"userID": "user_9",
            //"name": "name_user_9",
            //"score": 1,
            //"avatarID": "MICHAEL_BENNET",
            //"frameID": "0"},...
            //]

            let displayTxt = "";
            displayTxt += this.SelectedApplicationID;
            displayTxt += "\r\n";
            displayTxt += "\r\n";

            _LeaderBoardData.CurrentTopInfo = "";

            displayTxt += "Rank\tUser ID\tName\tScore";
            displayTxt += "\r\n";

            for (let idx = 0; idx < response.data.top.length; idx++) {
              let topItem = response.data.top[idx];

              displayTxt += `${idx + 1}\t${topItem.userID}\t${topItem.name}\t${
                topItem.score
              }`;
              displayTxt += "\r\n";
            }

            _LeaderBoardData.CurrentTopInfo = displayTxt;
          } else {
            Message({
              message: "Get Top User List: " + JSON.stringify(response.data),
              type: "error",
              duration: 5 * 1000,
            });

            _LeaderBoardData.CurrentTopInfo = "";
          }
        })
        .catch((error) => {
          Message({
            message: "Get Top User List: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });

      if (
        this.LeaderBoardData.CurrentTopNameIndex >= 0 &&
        this.LeaderBoardData.CurrentTopNameIndex <
          this.LeaderBoardData.TopNames.length &&
        this.LeaderBoardData.CurrentTopName !==
          this.LeaderBoardData.TopNames[
            this.LeaderBoardData.CurrentTopNameIndex
          ]
      ) {
        // update CurrentTopNameIndex
        for (let i = 0; i < this.LeaderBoardData.TopNames.length; i++) {
          if (
            this.LeaderBoardData.CurrentTopName ===
            this.LeaderBoardData.TopNames[i]
          ) {
            this.LeaderBoardData.CurrentTopNameIndex = i;
          }
        }
      }
    },
    Statistic_GetOldBackupListFile() {
      this.loading = true;
      this.oldBackupFileData = [];

      let promises = [];

      const getOldBackupFileList = async () => {
        let result = undefined;

        console.log("getOldBackupFileList: ");

        await HelperLeaderBoard_GetOldBackupFileList()
          .then((response) => {
            //Message({message: "on Sync CDN:" + JSON.stringify(response.data), type: "success", duration: 5 * 1000});

            let code = response.data.code;
            if (code === 0) {
              let retFileList = response.data.fileList;

              retFileList.sort(function (a, b) {
                if (a > b) {
                  return -1;
                }
                if (a < b) {
                  return 1;
                }
                return 0;
              });

              result = {
                result: "success",
                baseURL: response.data.baseURL,
                pathURL: response.data.pathURL,
                fileList: retFileList,
                error: "",
              };
            } else {
              result = {
                result: "failed",
                baseURL: "",
                pathURL: "",
                fileList: [],
                error: code,
              };
            }
          })
          .catch((error) => {
            result = {
              result: "failed",
              baseURL: "",
              pathURL: "",
              fileList: [],
              error: error,
            };
          });

        return result;
      };

      promises.push(getOldBackupFileList());

      Promise.all(promises)
        .then((response) => {
          this.loading = false;

          var result = response[0];

          if (result.result == "success") {
            Message({
              message: "Get old backup url " + result.pathURL,
              type: "success",
              duration: 5 * 1000,
            });

            let idx = 1;
            let baseURL = result.baseURL;
            let pathURL = result.pathURL;

            result.fileList.forEach((f) => {
              let link =
                baseURL + "/" + f + "?pathName=" + encodeURIComponent(pathURL);

              this.oldBackupFileData.push({
                id: idx,
                fileName: f,
                link: link,
              });

              idx++;
            });

            //Message({message: "Get Statistic file SUCCESS " + JSON.stringify(result), type: "success", duration: 5 * 1000});
          } else {
            Message({
              message: "Get old backup files FAILED " + JSON.stringify(result),
              type: "error",
              duration: 5 * 1000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          Message({
            message: "Get old backup files FAILED! " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    Statistic_GetErrorLogFile() {
      this.loading = true;
      this.logErrorFileData = [];

      let promises = [];

      const getErrorLogFileList = async () => {
        let result = undefined;

        console.log("getErrorLogFileList: ");

        await HelperLeaderBoard_GetCurrentErrorLogFileList()
          .then((response) => {
            //Message({message: "on Sync CDN:" + JSON.stringify(response.data), type: "success", duration: 5 * 1000});

            let code = response.data.code;
            if (code === 0) {
              let retFileList = response.data.fileList;

              retFileList.sort(function (a, b) {
                if (a > b) {
                  return -1;
                }
                if (a < b) {
                  return 1;
                }
                return 0;
              });

              result = {
                result: "success",
                baseURL: response.data.baseURL,
                pathURL: response.data.pathURL,
                fileList: retFileList,
                error: "",
              };
            } else {
              result = {
                result: "failed",
                baseURL: "",
                pathURL: "",
                fileList: [],
                error: code,
              };
            }
          })
          .catch((error) => {
            result = {
              result: "failed",
              baseURL: "",
              pathURL: "",
              fileList: [],
              error: error,
            };
          });

        return result;
      };

      promises.push(getErrorLogFileList());

      Promise.all(promises)
        .then((response) => {
          this.loading = false;

          var result = response[0];

          if (result.result == "success") {
            Message({
              message: "Get error log url " + result.pathURL,
              type: "success",
              duration: 5 * 1000,
            });

            let idx = 1;
            let baseURL = result.baseURL;
            let pathURL = result.pathURL;

            result.fileList.forEach((f) => {
              let link =
                baseURL + "/" + f + "?pathName=" + encodeURIComponent(pathURL);

              this.logErrorFileData.push({
                id: idx,
                fileName: f,
                link: link,
              });

              idx++;
            });

            //Message({message: "Get Error Log SUCCESS " + JSON.stringify(result), type: "success", duration: 5 * 1000});
          } else {
            Message({
              message: "Get old error log FAILED " + JSON.stringify(result),
              type: "error",
              duration: 5 * 1000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          Message({
            message: "Get old error log FAILED! " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    Statistic_GetLastRewardLogFile() {
      this.loading = true;
      this.logRewardFileData = [];

      let promises = [];

      const getRewardLogFileList = async (appID) => {
        let result = undefined;

        console.log("getRewardLogFileList: ");

        await HelperLeaderBoard_GetLastRewardLogFileList(appID)
          .then((response) => {
            //Message({message: "on Sync CDN:" + JSON.stringify(response.data), type: "success", duration: 5 * 1000});

            let code = response.data.code;
            if (code === 0) {
              let retFileList = response.data.fileList;

              retFileList.sort(function (a, b) {
                if (a > b) {
                  return -1;
                }
                if (a < b) {
                  return 1;
                }
                return 0;
              });

              result = {
                result: "success",
                baseURL: response.data.baseURL,
                pathURL: response.data.pathURL,
                fileList: retFileList,
                error: "",
              };
            } else {
              result = {
                result: "failed",
                baseURL: "",
                pathURL: "",
                fileList: [],
                error: code,
              };
            }
          })
          .catch((error) => {
            result = {
              result: "failed",
              baseURL: "",
              pathURL: "",
              fileList: [],
              error: error,
            };
          });

        return result;
      };

      promises.push(getRewardLogFileList(this.SelectedApplicationID));

      Promise.all(promises)
        .then((response) => {
          this.loading = false;

          var result = response[0];

          if (result.result == "success") {
            Message({
              message: "Get last reward log " + result.pathURL,
              type: "success",
              duration: 5 * 1000,
            });

            let idx = 1;
            let baseURL = result.baseURL;
            let pathURL = result.pathURL;

            result.fileList.forEach((f) => {
              let link =
                baseURL + "/" + f + "?pathName=" + encodeURIComponent(pathURL);

              this.logRewardFileData.push({
                id: idx,
                fileName: f,
                link: link,
              });

              idx++;
            });

            //Message({message: "Get Statistic file SUCCESS " + JSON.stringify(result), type: "success", duration: 5 * 1000});
          } else {
            Message({
              message:
                "Get last reward log files FAILED " + JSON.stringify(result),
              type: "error",
              duration: 5 * 1000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          Message({
            message: "Get last reward log files FAILED! " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    Statistic_OnLeaderBoardRefreshCheatLog() {
      this.loading = true;
      this.cheatLogFileData = [];

      let promises = [];

      const getLBCheatLogFileList = async () => {
        let result = undefined;

        console.log("Statistic_OnLeaderBoardRefreshCheatLog: ");

        await HelperLeaderboard_GetCheatLogFileList()
          .then((response) => {
            //Message({message: "on Sync CDN:" + JSON.stringify(response.data), type: "success", duration: 5 * 1000});

            let code = response.data.code;
            if (code === 0) {
              let retFileList = response.data.fileList;

              retFileList.sort(function (a, b) {
                if (a > b) {
                  return -1;
                }
                if (a < b) {
                  return 1;
                }
                return 0;
              });

              result = {
                result: "success",
                baseURL: response.data.baseURL,
                pathURL: response.data.pathURL,
                fileList: retFileList,
                error: "",
              };
            } else {
              result = {
                result: "failed",
                baseURL: "",
                pathURL: "",
                fileList: [],
                error: code,
              };
            }
          })
          .catch((error) => {
            result = {
              result: "failed",
              baseURL: "",
              pathURL: "",
              fileList: [],
              error: error,
            };
          });

        return result;
      };

      //Message({message: "Get Upload url " + urlCDN, type: "success", duration: 5 * 1000});

      promises.push(getLBCheatLogFileList());

      Promise.all(promises)
        .then((response) => {
          this.loading = false;

          var result = response[0];

          if (result.result == "success") {
            //Message({message: "Get cheat log url " + result.pathURL, type: "success", duration: 5 * 1000});

            let idx = 1;
            let baseURL = result.baseURL;
            let pathURL = result.pathURL;

            result.fileList.forEach((f) => {
              let link =
                baseURL + "/" + f + "?pathName=" + encodeURIComponent(pathURL);

              this.cheatLogFileData.push({
                id: idx,
                fileName: f,
                link: link,
              });

              idx++;
            });

            //Message({message: "Get Cheat file SUCCESS " + JSON.stringify(result), type: "success", duration: 5 * 1000});
          } else {
            Message({
              message: "Get Cheat log file FAILED " + JSON.stringify(result),
              type: "error",
              duration: 5 * 1000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          Message({
            message: "Get Cheat log Files Failed! " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.userprofile-container {
  margin: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
</style>
